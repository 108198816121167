/**
 * Format words into Capitalize except "of, the, and, by" words
 * @param "pure desire"
 * @returns "Pure Desire"
 */
export const formatCapitalizeWord = (value) => {
  const excludedWords = ['of', 'and', 'by'];
  if (!value) {
    return '';
  }

  // Split camelCase string into words
  const words = value
    .replace(/([a-z])([A-Z])/g, '$1 $2') // Insert space before uppercase letters
    .split(/[_\s-]+/); // Split by underscore, space, or dash

  const formattedWords = words.map((word) => {
    return excludedWords.includes(word.toLowerCase())
      ? word.toLowerCase()
      : word.charAt(0).toUpperCase() + word.slice(1);
  });

  return formattedWords.join(' ');
};

export const lowerCaseHyphenWord = (value) => value.replace(/\s+/g, '-').toLowerCase();

export const convertObjectToArray = (obj) => {
  return Object.entries(obj).map(([key, value]) => {
    return { assessmentItemId: key, answer: value };
  });
};

export const checkImage = (file) => {
  let err = '';
  if (!file) {
    err = "File doesn't exist.";
  }

  if (file.size > 1024 * 1024) {
    err = 'File size is too large, max 1mb.';
  }

  if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
    err = 'File format is incorrect.';
  }

  return err;
};

export function isEqual(obj1, obj2) {
  if (obj1 === obj2) {
    return true;
  }

  if (typeof obj1 !== typeof obj2 || obj1 === null || obj2 === null) {
    return false;
  }

  if (typeof obj1 !== 'object' || obj1 instanceof Date) {
    return obj1 === obj2;
  }

  const obj1Keys = Object.keys(obj1);
  const obj2Keys = Object.keys(obj2);

  if (obj1Keys.length !== obj2Keys.length) {
    return false;
  }

  return obj1Keys.every((key) => isEqual(obj1[key], obj2?.[key]));
}

export const formatCamelCase = (string) =>
  string
    .replace(/\./g, '')
    .toLowerCase()
    .trim()
    .split(/[.\-_\s]/g)
    .reduce((string, word) => {
      if (word) {
        // add this check to skip empty or undefined words
        return string + word[0].toUpperCase() + word.slice(1);
      } else {
        return string;
      }
    });

export const camelCaseKeys = (obj) => {
  const newObj = {};
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      const camelCaseKey = formatCamelCase(key);
      newObj[camelCaseKey] = obj[key];
    }
  }
  return newObj;
};

export const removeDuplicate = (array) => {
  array.filter((obj, index, self) => index === self.findIndex((t) => t._id === obj._id));
};

export function formatCurrency(number) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  });
  return formatter.format(number);
}

export default function initialsGenerator(name) {
  const words = name.split(' ');
  if (words.length === 1) {
    return name.charAt(0);
  } else {
    const [first, second] = words;
    return `${first.charAt(0)}${second.charAt(0)}`;
  }
}
