import React, { useEffect } from 'react';
import { Snackbar, Alert, Slide } from '@mui/material';
import useNotificationStore from '../../Hooks/useNotification';

function SlideTransition(props) {
  return <Slide {...props} direction="left" />;
}

const NotificationList = () => {
  const notifications = useNotificationStore((state) => state.notifications);
  const removeNotification = useNotificationStore((state) => state.removeNotification);

  useEffect(() => {
    notifications.forEach((notification) => {
      const timeout = setTimeout(() => {
        removeNotification(notification.id);
      }, 5000);

      return () => clearTimeout(timeout);
    });
  }, [notifications, removeNotification]);

  const handleClose = (id) => {
    removeNotification(id);
  };

  return (
    <>
      {notifications.map((notification) => (
        <Snackbar
          key={notification.id}
          open={true}
          autoHideDuration={5000}
          TransitionComponent={SlideTransition}
          onClose={() => handleClose(notification.id)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
        >
          <Alert onClose={() => handleClose(notification.id)} severity={notification.severity}>
            {notification.message}
          </Alert>
        </Snackbar>
      ))}
    </>
  );
};

export default NotificationList;
