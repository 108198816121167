/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { Grid } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import useQuill from '../../../../Hooks/useQuill';

import styles from './index.module.scss';

const AssessmentContent3 = ({ item, addContent, removeItem, errorContents }) => {
  const { ref: quillRef, adminModules: modules } = useQuill();
  const [body, setBody] = useState(item.options[0] ? item.options[0] : '');

  return (
    <Grid
      container
      spacing={3}
      direction="row"
      justifyContent="space-between"
      style={{ marginTop: '0', marginBottom: '1.5rem' }}
      alignItems="center"
    >
      <Grid item xs={11} sm={11.5}>
        <label>Content</label>
        <div style={{ position: 'relative' }}>
          <ReactQuill
            ref={quillRef}
            style={{ maxHeight: '400px', marginTop: '14px', borderRadius: '4px' }}
            value={body}
            modules={modules}
            theme="snow"
            onChange={(e) => {
              addContent(e);
              setBody(e);
            }}
            placeholder="Type here..."
          />

          {errorContents.some((errContent) => errContent?.key === item.key) && (
            <span className={styles.errorMessage}>Please fill in this field</span>
          )}
        </div>
      </Grid>
      <Grid item xs={1} sm={0.5} align="right">
        <RemoveIcon
          onClick={removeItem}
          style={{ background: '#E85E40', borderRadius: '50%', fill: 'white', marginTop: '60px', cursor: 'pointer' }}
        />
      </Grid>
    </Grid>
  );
};

export default AssessmentContent3;
