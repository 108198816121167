import React, { Fragment } from 'react';
import { API_URL } from '../../constant';
import styles from './Avatar.module.scss';

export const Avatar = ({ src, name, sx }) => {
  return (
    <Fragment>
      {src === '' ? (
        sx ? (
          <span className={styles.menu_profile} style={{ width: sx.width, height: sx.height }}>
            {name.charAt()}
          </span>
        ) : (
          <span className={styles.menu_profile}>{name.charAt(0)}</span>
        )
      ) : sx ? (
        <img
          src={`${API_URL}/${src}`}
          alt="avatar"
          className={styles.menu_profile}
          style={{ width: sx.width, height: sx.height }}
        />
      ) : (
        <img src={`${API_URL}/${src}`} alt="avatar" className={styles.menu_profile} />
      )}
    </Fragment>
  );
};
