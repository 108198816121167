import React from 'react';
import PropTypes from 'prop-types';
import styles from './Card.module.scss';

const Card = ({ children, direction, className }) => {
  return <div className={`${styles.card} ${styles[direction]} ${className}`}>{children}</div>;
};

Card.propTypes = {
  children: PropTypes.node,
  direction: PropTypes.oneOf(['col', 'row']),
  className: PropTypes.string
};

export default Card;
