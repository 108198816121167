import { useRef, useState, useEffect } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import styles from './Dropdown.module.scss';
function DropDown({
  children,
  options,
  selectedOption,
  multiSelect,
  selectedOptions,
  height,
  width,
  showDropDown,
  setShowDropDown,
  onSelect,
  dropUp
}) {
  const dropdownRef = useRef(null);
  const [highlightedIndex, setHighlightedIndex] = useState(0);
  const positionClass = dropUp
    ? {
        bottom: '100%',
        marginBottom: '.25rem'
      }
    : {
        top: '100%',
        marginTop: '.25rem'
      };

  const handleKeyDown = (event) => {
    switch (event.key) {
      case 'ArrowUp':
        event.preventDefault();
        setHighlightedIndex((prev) => (prev === 0 ? options.length - 1 : prev - 1));
        break;
      case 'ArrowDown':
        event.preventDefault();
        setHighlightedIndex((prev) => (prev === options.length - 1 ? 0 : prev + 1));
        break;
      case 'Enter':
        event.preventDefault();
        onSelect(options[highlightedIndex]);
        setShowDropDown(false);
        break;
      case 'Escape':
        event.preventDefault();
        setShowDropDown(false);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropDown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setShowDropDown]);

  const handleSelect = (option) => {
    if (!multiSelect) {
      setShowDropDown(false);
    }
    onSelect(option);
    setHighlightedIndex(0);
  };

  const dropdownWidth = width
    ? {
        width: `${width}px`
      }
    : {
        width: '100%'
      };
  const dropdownHeight = height
    ? {
        maxHeight: `${height}px`
      }
    : {
        maxHeight: '288px'
      };

  return (
    <div className={styles.dropdown} ref={dropdownRef}>
      <div className={styles.dropdown_container} onKeyDown={handleKeyDown} tabIndex={0}>
        <div className={styles.dropdown_content}>{children}</div>
        {showDropDown && (
          <div
            className={styles.dropdown_popover}
            style={{
              ...dropdownHeight,
              ...dropdownWidth,
              ...positionClass
            }}
          >
            <ul className={styles.dropdown_list}>
              {options.map((option, index) => (
                <li
                  key={option.id}
                  className={`${styles.dropdown_item} ${highlightedIndex === index && styles.dropdown_highlight}`}
                  onMouseEnter={() => setHighlightedIndex(index)}
                  onMouseLeave={() => setHighlightedIndex(0)}
                  onClick={() => handleSelect(option)}
                >
                  <div className={`${styles.option} ${selectedOption && styles.option_selected}`}>
                    <div className={styles.option}>
                      {multiSelect && (
                        <input
                          type="checkbox"
                          className={styles.checkbox}
                          name={option.id || ''}
                          checked={selectedOptions?.some((selectedOption) => selectedOption.id === option.id)}
                          onChange={() => handleSelect(option)}
                        />
                      )}
                      {option.component ? (
                        <>{option.component}</>
                      ) : (
                        <span className={styles.truncate}>{option.label}</span>
                      )}
                    </div>
                    {selectedOption && selectedOption.id === option.id && <CheckCircleIcon color="#006C91" />}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

DropDown.defaultProps = {
  multiSelect: false,
  dropUp: false
};

export default DropDown;
