import React, { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { Box, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import RenderString from '../RenderString';
import axios from 'axios';
import { API_URL } from '../../constant';
import styles from './Announcement.module.scss';
import useUser from '../../Hooks/useUser';
import { getDataAPI } from '../../Helpers/FetchAPI';
import useAnnouncement from '../../Hooks/useAnnouncement';

const AlertBar = () => {
  const { currentUser } = useUser();
  const queryClient = useQueryClient();

  const { data: announcements, isError } = useQuery(
    'user-announcement',
    async () => await getDataAPI('/api/announcement').then((res) => res.data)
  );

  const handleAlertClose = async (id) => {
    await axios.patch(`${API_URL}/api/announcement/${id}`, {
      userId: currentUser?._id
    });
  };

  if (announcements?.length <= 0) {
    return null;
  }

  return null;

  return (
    <Box className={styles.announcement} sx={{ color: '#fff', fontWeight: 500 }}>
      <Box className={styles.announcement_content} sx={{ display: 'flex', flexWrap: 'nowrap' }}>
        {announcements.map((announcement) => (
          <Typography
            key={announcement._id}
            variant="body1"
            component="span"
            sx={{ marginRight: '10rem', overflow: 'hidden' }}
          >
            <RenderString content={announcement.description} onClick={() => removeAnnouncement(announcement._id)} />
          </Typography>
        ))}
      </Box>
    </Box>
  );
};

export default AlertBar;
