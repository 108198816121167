import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { mainTheme } from '../../mainTheme';
import ProtectedRoutes from '../../components/ProtectedRoutes';
import UserContextProvider from '../../Context/User';
import WorkbookContextProvider from '../../Context/Workbook';
import NotebooksContextProvider from '../../Context/Notebooks';
import CreditsContextProvider from '../../Context/Credits';
import AdminAssessmentsContextProvider from '../../Context/AdminAssessments';
import UserAssessmentsContextProvider from '../../Context/UserAssessments';
import UserCartContextProvider from '../../Context/UserCart';

export const App = () => {
  return (
    <ThemeProvider theme={mainTheme}>
      <UserContextProvider>
        <WorkbookContextProvider>
          <CreditsContextProvider>
            <NotebooksContextProvider>
              <AdminAssessmentsContextProvider>
                <UserAssessmentsContextProvider>
                  <UserCartContextProvider>
                    <ProtectedRoutes />
                  </UserCartContextProvider>
                </UserAssessmentsContextProvider>
              </AdminAssessmentsContextProvider>
            </NotebooksContextProvider>
          </CreditsContextProvider>
        </WorkbookContextProvider>
      </UserContextProvider>
    </ThemeProvider>
  );
};
