import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

const Search = ({ query, setQuery, placeholder }) => {
  const handleChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <Paper
      component="form"
      sx={{ p: '1rem 1.5rem', display: 'flex', alignItems: 'center' }}
      className="searchBar"
      onSubmit={handleSubmit}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        onChange={(event) => handleChange(event)}
        placeholder={placeholder ?? 'Search'}
        inputProps={{ 'aria-label': 'search' }}
        value={query}
      />
      <IconButton type="button" aria-label="search">
        <SearchIcon />
      </IconButton>
    </Paper>
  );
};

export default Search;
