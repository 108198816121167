/* eslint-disable react/no-danger */
import React from 'react';
import styles from './RenderString.module.scss';
import './QuillOverride.scss';

const RenderString = ({ content, className, ...props }) => (
  <div
    dangerouslySetInnerHTML={{ __html: content }}
    className={`${styles.render_string} ${className} resource-styles`}
    {...props}
  />
);

export default RenderString;
