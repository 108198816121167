import React, { useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { OutlinedInput, Divider, IconButton, InputAdornment } from '@mui/material';
import MdVisibility from '@mui/icons-material/Visibility';
import MdVisibilityOff from '@mui/icons-material/VisibilityOff';
import { mainTheme } from '../../mainTheme';
import { federatedSignIn, signUp } from '../../utils/cognitoAuth';
import OtherButton from '../../components/Button';
import { ReactComponent as GoogleIcon } from '../../images/icons/google.svg';

const SignUp = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const userEmail = urlParams.get('email');

  const [registerEmail, setRegisterEmail] = useState(userEmail ? userEmail : '');
  const [registerPassword, setRegisterPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  // if(userEmail){
  //   setRegisterEmail(userEmail);
  // }

  function handleRegister(e) {
    // e.preventDefault();
    if (registerEmail && registerPassword) {
      signUp(registerEmail.trim(), registerPassword.trim())
        .then((data) => {
          navigate('/?message=Please%20activate%20your%20account');
          // return(
          //     <Navigate to={{
          //         pathname: '/',
          //         state: { id: '123' }
          //     }}
          //     />
          // )
        })
        .catch((err) => {
          setErrorMessage(err.message);
          if (err.code === 'UsernameExistsException') {
            navigate('/');
          }
        });
    } else {
      setErrorMessage('Email and Password fields are required.');
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    handleRegister();
  };

  return (
    <ThemeProvider theme={mainTheme}>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start'
          }}
        >
          <Typography color="primary.neutral" component="h1" variant="h1" sx={{ mb: 2 }}>
            Sign Up
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }} style={{ width: '90%' }}>
            {errorMessage && (
              <p className="error" style={{ fontWeight: 'bold', color: 'red', fontSize: '18px' }}>
                {' '}
                {errorMessage}{' '}
              </p>
            )}

            <div className="signin_form">
              <div className="label_textfield">
                <span>Email Address*</span>

                <TextField
                  required
                  fullWidth
                  id="email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  onChange={(e) => setRegisterEmail(e.target.value.toLowerCase())}
                  value={registerEmail}
                  inputProps={{ className: 'textField' }}
                />
              </div>
              <div className="label_textfield">
                <span>Password*</span>
                <OutlinedInput
                  required
                  fullWidth
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  autoComplete="current-password"
                  onChange={(e) => setRegisterPassword(e.target.value)}
                  value={registerPassword}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <MdVisibility className="visible" /> : <MdVisibilityOff className="visible" />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </div>
            </div>
            <div className="signin_buttons">
              <div>
                <Button type="submit" variant="contained" sx={{ mt: 1 }}>
                  Sign Up
                </Button>
              </div>
              <Divider>Or</Divider>
              <div className="signin_providers">
                <Typography component="p" variant="p">
                  Sign In Using
                </Typography>
                <OtherButton onClick={() => federatedSignIn('Google')} variant="primary" className="button_google">
                  <GoogleIcon />
                </OtherButton>
              </div>
              <Link href="/" variant="body2" sx={{ mt: 4 }} style={{ textAlign: 'right' }}>
                Already have an account? Sign In
              </Link>
            </div>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

// import React, { useState } from "react";
// import { signUp } from "../../utils/cognitoAuth";
//
// export default function Register() {
//     const [registerEmail, setRegisterEmail] = useState("");
//     const [registerPassword, setRegisterPassword] = useState("");
//
//     function handleRegister(e) {
//         e.preventDefault();
//         signUp(registerEmail, registerPassword).catch((err) =>
//             console.log(err)
//         );
//     }
//     return (
//         <>
//             <h3>Register</h3>
//             <form onSubmit={handleRegister}>
//                 <input
//                     placeholder="email"
//                     onChange={(e) => setRegisterEmail(e.target.value)}
//                     value={registerEmail}
//                 />
//                 <input
//                     type="password"
//                     onChange={(e) => setRegisterPassword(e.target.value)}
//                     value={registerPassword}
//                     placeholder="password"
//                 />
//                 <button>Register</button>
//             </form>
//         </>
//     );
// }

export default SignUp;
