import { create } from 'zustand';

const useAnnouncement = create((set) => ({
  announcements: [],
  addAnnouncement: (announcement) => set((state) => ({ announcements: [...state.announcements, announcement] })),
  removeAnnouncement: (id) =>
    set((state) => ({
      announcements: state.announcements.filter((announcement) => announcement.id !== id)
    })),
  setAnnouncement: (announcements) => set({ announcements })
}));

export default useAnnouncement;
