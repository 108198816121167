import { createTheme } from '@mui/material/styles';

export const mainTheme = createTheme({
  palette: {
    primary: {
      main: '#00998c',
      neutral: '#212121'
    },
    secondary: {
      main: '#36505b'
    },
    background: {
      default: '#ffffff'
    }
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'primary' && {
              background: 'linear-gradient(to right, #00998c, #36505b)',
              boxShadow: 'none',
              textTransform: 'none',
              fontWeight: '600',
              fontSize: '15px',
              '&:hover': {
                boxShadow: '0 0 15px rgba(0,0,0,0.2)',
                background: 'linear-gradient(to right, #0B6776, #0D6257)'
              }
            }),
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'secondary' && {
              transition: 'background-image 0.5s linear',
              background: 'linear-gradient(to bottom, #FCA659, #E85E40)',
              boxShadow: 'none',
              textTransform: 'none',
              fontWeight: '600',
              fontSize: '15px',
              '&:hover': {
                boxShadow: 'none',
                background: 'linear-gradient(to bottom, #E85E40, #FCA659)'
              }
            }),
          ...(ownerState.variant === 'outlined' &&
            ownerState.color === 'primary' && {
              boxShadow: 'none',
              border: '2px solid #00998c',
              transition: '0.3s all',
              textTransform: 'none',
              fontWeight: '600',
              fontSize: '15px',
              '&:hover': {
                background: '#00998c',
                border: '2px solid #00998c',
                color: '#ffffff'
              }
            }),
          ...(ownerState.variant === 'outlined' &&
            ownerState.color === 'secondary' && {
              boxShadow: 'none',
              border: '2px solid #E85E40',
              transition: '0.3s all',
              textTransform: 'none',
              fontWeight: '600',
              fontSize: '15px',
              color: '#E85E40',
              '&:hover': {
                background: '#E85E40',
                border: '2px solid #E85E40',
                color: '#ffffff'
              }
            })
        })
      }
    },

    MuiIconButton: {
      styleOverrides: {
        root: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          width: 'auto',
          fontSize: '15px',
          lineHeight: '1',
          fontFamily: 'Montserrat',
          color: '#212121',
          fontWeight: '500'
        }
      }
    },

    MuiBox: {
      styleOverrides: {
        root: {
          background: '#ffffff'
        }
      }
    },

    MuiToolbar: {
      styleOverrides: {
        root: {
          background: '#ffffff',
          boxShadow: 'none',
          textAlign: 'left',
          color: '#212121',
          padding: '0 70px 0 45px !important'
        }
      }
    },

    MuiList: {
      styleOverrides: {
        root: {
          color: '#212121',
          fontWeight: '500',
          fontSize: '15px'
        }
      }
    },

    MuiListItemButton: {
      styleOverrides: {
        root: {
          svg: {
            padding: '5px',
            boxShadow: '0 0 10px rgba(0,0,0,0.1)',
            fontSize: '35px'
          },
          '&.Mui-selected': {
            svg: {
              fill: '#ffffff',
              boxShadow: 'none'
            },
            background: 'linear-gradient(30deg, #FCA659, #E85E40)',
            borderRadius: '10px'
          },
          '&.Mui-focusVisible': {
            svg: {
              fill: '#ffffff',
              boxShadow: 'none'
            },
            background: 'linear-gradient(30deg, #FCA659, #E85E40)',
            borderRadius: '10px'
          },
          ':hover': {
            svg: {
              fill: '#212121',
              boxShadow: 'none'
            },
            background: 'linear-gradient(30deg, #FCA659, #E85E40)',
            borderRadius: '10px',
            color: '#212121'
          }
        }
      }
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          background: '#ffffff',
          boxShadow: 'none',
          borderRight: '0 !important'
        }
      }
    },

    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: '#EBEBEB'
        },
        bar: {
          backgroundColor: '#00998c'
        }
      }
    },

    MuiContainer: {
      styleOverrides: {
        root: {
          padding: '50px 60px 0 30px !important',
          '@media (max-width: 767px)': {
            padding: '50px 14px !important'
          }
        }
      }
    },

    MuiLink: {
      styleOverrides: {
        root: {
          color: '#36505b',
          textDecoration: 'none',
          fontWeight: '600'
        }
      }
    },

    MuiTypography: {
      styleOverrides: {
        h1: {
          fontSize: '2.5rem',
          fontWeight: '500',
          color: '#212121'
        },
        p: {
          color: '#212121'
        }
      }
    },

    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '18px',
          fontWeight: '500'
        },
        head: {
          fontSize: '16px',
          padding: '15px 10px'
        }
      }
    },

    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: '#E85E40'
          }
        }
      }
    },

    MuiSlider: {
      styleOverrides: {
        markLabel: {
          marginTop: 6,
          transform: 'translateX(-100%)',
          '&[data-index="0"]': {
            transform: 'none'
          }
        }
      }
    }
  },

  typography: {
    h2: {
      fontSize: '32px',
      color: '#212121',
      lineHeight: '36px',
      fontWeight: '500'
    },
    h3: {
      fontSize: '50px',
      color: '#212121',
      lineHeight: '50px',
      fontWeight: '500',
      textAlign: 'center'
    },
    h4: {
      fontSize: '25px',
      color: '#212121',
      lineHeight: '25px',
      fontWeight: '500',
      textAlign: 'center'
    },
    fontSize: 20,
    fontFamily: ['Montserrat', 'sans-serif'].join(',')
  }
});
