import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Card from '../Card';
import styles from './Coupon.module.scss';
import { Discount, Close } from '@mui/icons-material';
import moment from 'moment';
import Button from '../Button';

const Coupon = ({ coupon, selected, setSelectedCoupon, onRemoveCoupon, variant }) => {
  const { code, name, percent_off, amount_off, redeem_by } = coupon;
  const [onHover, setOnHover] = useState(false);

  if (variant === 'tiny') {
    return (
      <div className={styles.coupon_tiny} onMouseEnter={() => setOnHover(true)} onMouseLeave={() => setOnHover(false)}>
        <span>Discount Code "{code}" applied on order</span>
        {onHover && (
          <Close
            onClick={onRemoveCoupon}
            sx={{
              color: '#fff'
            }}
          />
        )}
      </div>
    );
  }

  return (
    <Card>
      <div className={styles.coupon}>
        <span className={styles.coupon_logo}>
          <Discount />
        </span>
        <div className={styles.coupon_info}>
          <div className={styles.coupon_code}>{code}</div>
          <div className={styles.coupon_label}>{name}</div>
          {redeem_by && (
            <span className={styles.coupon_valid}>Valid until: {moment.unix(redeem_by).format('YYYY-MM-DD')}</span>
          )}
        </div>
        <div className={styles.coupon_detail}>
          <span className={styles.coupon_off}>{percent_off ? `${percent_off} %` : `${amount_off} off`}</span>
          <div className={styles.coupon_buttons}>
            <Button outline disabled={selected} onClick={() => setSelectedCoupon(coupon)}>
              {selected ? 'Applied' : 'Apply'}
            </Button>
            <Button outline variant="warning" onClick={onRemoveCoupon}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Card>
  );
};

Coupon.propTypes = {
  coupon: PropTypes.object,
  variant: PropTypes.oneOf(['default', 'tiny']),
  selected: PropTypes.bool,
  onRemoveCoupon: PropTypes.func,
  setSelectedCoupon: PropTypes.func
};

Coupon.defaultProps = {
  variant: 'default'
};

export default Coupon;
