import React, { createContext, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import { postDataAPI } from '../../Helpers/FetchAPI';
import { API_URL } from '../../constant';
import useUser from '../../Hooks/useUser';

function getUserWorkbookItemsForCredit(userWorkbookItems, credit_id) {
  return userWorkbookItems.filter(({ credit_id: itemCreditId }) => itemCreditId.toString() === credit_id.toString());
}
function getMostRecentUpdate(userWorkbookItemsForCredit) {
  const mostRecentItem = userWorkbookItemsForCredit.reduce(
    (mostRecent, currentItem) => (currentItem.updatedAt > mostRecent.updatedAt ? currentItem : mostRecent),
    userWorkbookItemsForCredit[0] || null
  );
  return mostRecentItem ? mostRecentItem.updatedAt : null;
}
function getUpdatedSections(sections, userWorkbookItemsForCredit) {
  return sections.map((section) => {
    const updatedSectionItems = section.items.map((item) => {
      if (!item.item_id) {
        return null;
      }
      const userWorkbookItem = userWorkbookItemsForCredit.find(
        ({ item_id }) => item_id.toString() === item.item_id._id.toString()
      );
      return {
        item_id: {
          completed: !!userWorkbookItem?.date_complete
        }
      };
    });
    const sectionCompleted = updatedSectionItems.every((item) => item && item.item_id.completed);
    return {
      items: updatedSectionItems.filter((item) => item !== null),
      completed: sectionCompleted
    };
  });
}

export const WorkbookContext = createContext({
  userWorkbooks: [],
  getUserClaimedWorkbooks: () => {},
  isLoading: false
});

const WorkbookContextProvider = ({ children }) => {
  const { currentUser } = useUser();

  const getUserClaimedWorkbooks = async () => {
    if (currentUser) {
      const userClaimed = await axios.get(`${API_URL}/api/user/workbooks/${currentUser?._id}`).then((res) => res.data);
      const { credit_ids, claimedWorkbooks } = userClaimed;
      const userWorkbookItems = await postDataAPI('/api/user/workbook/items', {
        userId: currentUser?._id,
        creditIds: credit_ids
      }).then((res) => res.data);
      const newClaimedWorkbooks = claimedWorkbooks.map(({ workbook_id, credit_id }) => {
        const userWorkbookItemsForCredit = getUserWorkbookItemsForCredit(userWorkbookItems, credit_id);
        const mostRecentUpdate = getMostRecentUpdate(userWorkbookItemsForCredit);
        const sections = getUpdatedSections(workbook_id.sections, userWorkbookItemsForCredit);
        return {
          ...workbook_id,
          credit_id,
          sections,
          updatedAt: mostRecentUpdate
        };
      });
      return newClaimedWorkbooks;
    }
  };

  return (
    <WorkbookContext.Provider
      value={{
        getUserClaimedWorkbooks
      }}
    >
      {children}
    </WorkbookContext.Provider>
  );
};

export default WorkbookContextProvider;
