import { useState, useEffect, useRef } from 'react';
import { isEqual } from '../../Helpers/Functions';

export const SubmitListener = ({ formik, initialValues, handleSubmitWithoutNext, setSaving }) => {
  const [lastValues, setLastValues] = useState(formik.values);


  useEffect(() => {
      const valuesEqualInitialValues = isEqual(formik.values, initialValues);
      const autoSave = setInterval(() => {
        if (!isEqual(formik.values, lastValues) && !valuesEqualInitialValues && Object.keys(formik.values).length !== 0 ) {
          // setSaving({
          //   message: 'Saving...',
          //   disabled: true
          // });
          handleSubmitWithoutNext();
          setLastValues(formik.values);
        }
      }, 1000);

      return () => {
        clearInterval(autoSave);
      };
  }, [lastValues, formik.values, initialValues, formik, handleSubmitWithoutNext]);

  return null;
};
