import { useQuery, useQueryClient, useMutation } from 'react-query';
import { deleteDataAPI, getDataAPI, putDataAPI } from '../Helpers/FetchAPI';
import useAuth from './useAuth';

const selectAddress = async (params) => {
  const data = {
    defaultAddress: params.defaultAddress
  };
  const res = await putDataAPI(`/user/update-user/${params.id}`, data);

  return res.data;
};

const useAddressBook = (id) => {
  const { refetch } = useAuth();
  const {
    data: addressBooksData,
    isLoading: addressBooksLoading,
    isError: addressBooksError,
    status: addressBooksStatus,
    isSuccess: isSuccessAddress
  } = useQuery(
    [id, 'address-books'],
    async () => await getDataAPI(`/api/address-books?userId=${id}`).then((res) => res.data),
    {
      enabled: !!id
    }
  );
  const queryClient = useQueryClient();

  const invalidateAddress = () => {
    queryClient.invalidateQueries([id, 'address-books']);
  };

  const { mutate: updateAddress } = useMutation((params) => selectAddress(params), {
    onSettled: () => {
      queryClient.invalidateQueries([id, 'address-books']);
      refetch();
    }
  });

  const { mutate: removeAddress } = useMutation(async (params) => await deleteDataAPI(`/api/address-book/${params}`), {
    onSettled: () => {
      invalidateAddress();
    }
  });

  return {
    addressBooksData: addressBooksData || [],
    addressBooksLoading,
    addressBooksStatus,
    addressBooksError,
    removeAddress,
    isSuccessAddress,
    invalidateAddress,
    updateAddress
  };
};

export default useAddressBook;
