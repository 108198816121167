import { useContext } from 'react';
import { UserContext } from '../Context/User';

const useUser = () => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('useUser must be within a UserContextProvider');
  }

  return context;
};

export default useUser;
