/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import { Box, Button, Grid, Snackbar, Typography } from '@mui/material';
import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import MenuIcon from '@mui/icons-material/Menu';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
// import CreateAssessment from './CreateAssessment';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import axios from 'axios';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import Alert from '@mui/material/Alert';
import CreateLesson from './CreateLesson';
import EditLessn from './EditLesson';
import CreateAssessment from './CreateAssessment/CreateAssessment';
import EditAssessment from './EditAssessment';
import Modal from '../../Modal';

const CreateSectionContents = ({ popup, setOpen, setPopup, workBookId, sectionId, titleInfo }) => {
  const queryClient = useQueryClient();

  const [lesson, setLesson] = useState(false);
  const [assessment, setAssessment] = useState(false);
  const [deleteItem, setDeleteItem] = useState(false);

  const [activeItem, setActiveItem] = useState({});
  const [snack, setSnack] = useState(false);

  const SuccessNotif = () => {
    return (
      <Snackbar autoHideDuration={6000} open={snack} onClose={handleClose}>
        <Alert severity="success">Save Successful!</Alert>
      </Snackbar>
    );
  };

  const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnack(false);
  };

  const itemQuery = useQuery(['sectionItems', sectionId], (params) => fetchWorkBookItems(params.queryKey[1]));

  const removeItemMutation = useMutation(
    (params) => {
      return axios.delete(`${process.env.REACT_APP_API_URL}/workbookItem/delete-workbookitem/${params._id}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['sectionItems']);
        queryClient.invalidateQueries('bookSection');
        setDeleteItem(false);
        setOpen(false);
        setActiveItem({});
      },
      onError: (err) => {}
    }
  );

  const updateSectionItemMutation = useMutation(
    (params) => {
      return axios.put(`${process.env.REACT_APP_API_URL}/workbookItem/update-itemorder`, params);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['sectionItems']);
      },
      onError: (err) => {
        alert(err);
      }
    }
  );

  const sectionItems = itemQuery.data || [];

  const handleDragandDrop = (result) => {
    if (!result.destination) return;

    const tempData = Array.from(sectionItems);
    const [source_data] = tempData.splice(result.source.index, 1);
    tempData.splice(result.destination.index, 0, source_data);

    updateSectionItemMutation.mutate(tempData);
  };

  return (
    <>
      <SuccessNotif />
      {lesson && (
        <CreateLesson
          open={() => {
            setLesson(false);
            setOpen(false);
          }}
          workBookId={workBookId}
          sectionId={sectionId}
          titleInfo={titleInfo}
        />
      )}
      {assessment && (
        <CreateAssessment
          open={() => {
            setAssessment(false);
            setOpen(false);
          }}
          workBookId={workBookId}
          sectionId={sectionId}
          titleInfo={titleInfo}
        />
      )}

      {deleteItem && (
        <Modal
          onClose={() => {
            setDeleteItem(false);
            setOpen(false);
          }}
        >
          <div
            style={{
              height: '100%',
              width: '100%',
              overflowY: 'auto'
            }}
          >
            <Box className="delete-modal">
              <div>
                <Typography variant="h1" style={{ padding: '10px' }}>
                  Delete
                </Typography>
                <Typography>Are you sure you want to delete this?</Typography>
                <Grid container justifyContent="space-around" style={{ paddingTop: '30px' }}>
                  <Grid item>
                    <Button
                      className="btn secondary"
                      onClick={() => {
                        setDeleteItem(false);
                        setOpen(false);
                        setActiveItem({});
                      }}
                    >
                      <b>No, go back</b>
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      className="btn third"
                      onClick={() => {
                        removeItemMutation.mutate({ _id: activeItem._id });
                        removeItemMutation.mutate({ _id: activeItem._id });
                      }}
                    >
                      <b>Yes, I’m sure</b>
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Box>
          </div>
        </Modal>
      )}

      {popup &&
        (activeItem.type === 'Lesson' || activeItem.type === 'Content' ? (
          <EditLessn
            snack={snack}
            setSnack={setSnack}
            item={activeItem}
            close={() => {
              setPopup(false);
              setOpen(false);
            }}
            titleInfo={titleInfo}
            style={{
              width: '200%'
            }}
          />
        ) : (
          <EditAssessment
            snack={snack}
            setSnack={setSnack}
            item={activeItem}
            close={() => {
              setPopup(false);
              setOpen(false);
            }}
            titleInfo={titleInfo}
          />
        ))}
      <DragDropContext onDragEnd={handleDragandDrop} isDragDisabled={popup}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {!!sectionItems.length &&
                sectionItems.map((item, index) => (
                  <Draggable
                    key={`item-${item._id}`}
                    draggableId={`item-${item._id}`}
                    index={index}
                    isDragDisabled={popup}
                  >
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <Box
                          key={item._id}
                          sx={{ padding: '24px', margin: '20px 0', border: '2px solid #FFA659', borderRadius: '4px' }}
                        >
                          <Grid
                            container
                            spacing={3}
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Grid item>
                              <Grid container spacing={3} direction="row" justifyContent="" alignItems="center">
                                <Grid item>
                                  <label className="label">{item.type}</label>
                                </Grid>
                                <Grid item style={{ display: 'flex' }}>
                                  <MenuIcon style={{ fontSize: '32px', fill: '#FFA659' }} />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item>
                              <Grid container spacing={3} direction="row" alignItems="center">
                                <Grid item>
                                  <label className="label">{item.title}</label>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item>
                              <Grid container spacing={3} alignItems="center">
                                <Grid
                                  item
                                  style={{ display: 'flex', cursor: 'pointer ' }}
                                  onClick={() => {
                                    setPopup(true);
                                    setOpen(true);
                                    setActiveItem(item);
                                  }}
                                >
                                  <ModeEditOutlineOutlinedIcon />
                                  {/*<span className="label">Edit</span>*/}
                                </Grid>
                                <Grid
                                  item
                                  style={{ display: 'flex', cursor: 'pointer ' }}
                                  onClick={() => {
                                    setDeleteItem(true);
                                    setOpen(true);
                                    setActiveItem(item);
                                  }}
                                >
                                  <DeleteOutlineOutlinedIcon style={{ fill: 'red' }} />
                                  {/*<span className="label">Delete</span>*/}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Box>
                      </div>
                    )}
                  </Draggable>
                ))}

              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <Box
        className="dashed-border"
        sx={{
          minHeight: '130px',
          borderRadius: '4px',
          margin: '20px 0',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Grid container spacing={10} direction="row" justifyContent="center" alignItems="center">
          <Grid item>
            <div
              className="icon_wrapper"
              onClick={() => {
                setLesson(true);
                setOpen(true);
              }}
            >
              <Avatar style={{ backgroundColor: '#FFA659', fontWeight: 'bold' }}>C</Avatar>
            </div>
          </Grid>
          <Grid item>
            <div
              className="icon_wrapper"
              onClick={() => {
                setOpen(true);
                setAssessment(true);
              }}
            >
              <Avatar style={{ backgroundColor: '#FFA659', fontWeight: 'bold' }}>A</Avatar>
            </div>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const fetchWorkBookItems = (id) =>
  axios.get(`${process.env.REACT_APP_API_URL}/workbookItem/${id}`).then((response) => response.data);

export default CreateSectionContents;
