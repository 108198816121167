import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from './ProgressBar.module.scss';

const ProgressBar = ({ value, maximumValue, className, showPercentage }) => {
  const progressBarWidth = useMemo(() => {
    if (maximumValue === 0) return '0%'
    else return `${((value * 100) / maximumValue).toFixed(0)}%`;
  }, [value, maximumValue]);

  return (
    <div className={`${styles.progressbar} ${className}`}>
      <div className={styles.progressbar_container}>
        <div
          className={styles.progressbar_line}
          style={{
            width: progressBarWidth
          }}
        />
      </div>
      {showPercentage && <span className={styles.progressbar_text}>{progressBarWidth}</span>}
    </div>
  );
};

ProgressBar.propTypes = {
  value: PropTypes.number,
  maximumValue: PropTypes.number,
  className: PropTypes.string,
  showPercentage: PropTypes.bool
};

ProgressBar.defaultProps = {
  value: 0,
  showPercentage: true
};

export default ProgressBar;
