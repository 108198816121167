import React from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListSubheader from '@mui/material/ListSubheader';
import TranslateIcon from '@mui/icons-material/Translate';
import { languageMap } from '../../languageMap';

import styles from './LanguageSelect.module.scss';

const LanguageSelect = () => {
  const i18nextLng_item = localStorage.getItem('i18nextLng');
  let selected;
  if (i18nextLng_item) {
    selected = localStorage.getItem('i18nextLng').substring(0, 2) || 'en';
  } else {
    selected = 'en';
  }

  const { t } = useTranslation();

  const [menuAnchor, setMenuAnchor] = React.useState(null);
  React.useEffect(() => {
    document.body.dir = languageMap[selected].dir;
  }, [menuAnchor, selected]);

  return (
    <div className={styles.languageselect}>
      <Button
        onClick={({ currentTarget }) => setMenuAnchor(currentTarget)}
        style={{
          fontFamily: 'inherit',
          fontSize: 'inherit',
          fontWeight: 400
        }}
      >
        <span style={{ marginRight: '10px', marginTop: '6px' }}>
          <TranslateIcon />
        </span>
        {languageMap[selected].label}
        <ArrowDropDown fontSize="large" />
      </Button>
      <Popover
        open={!!menuAnchor}
        anchorEl={menuAnchor}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <List
          sx={{
            width: '100%',
            maxWidth: 360,
            padding: '0.5rem 0',
            backgroundColor: '#fafafa'
          }}
          subheader={
            <ListSubheader
              component="div"
              sx={{
                paddingBottom: 0,
                marginBottom: 0,
                fontSize: '1.4rem',
                fontWeight: '500'
              }}
            >
              {t('select_language')}
            </ListSubheader>
          }
        >
          {Object.keys(languageMap)?.map((item) => (
            <ListItem
              key={item}
              li
              onClick={() => {
                i18next.changeLanguage(item);
                setMenuAnchor(null);
              }}
              sx={{
                fontSize: '1.3rem',
                cursor: 'pointer'
              }}
            >
              {languageMap[item].label}
            </ListItem>
          ))}
        </List>
      </Popover>
    </div>
  );
};

export default LanguageSelect;
