import { create } from 'zustand';

const useBackdrop = create((set) => ({
  isVisible: false,
  message: null,
  showBackdrop: (message) => set({ isVisible: true, message }),
  hideBackdrop: () => set({ isVisible: false, message: null })
}));

export default useBackdrop;
