import React from 'react';
import AuthContextProvider from './Context/Auth';
import { withAuthentication } from './hoc/withAuthentication';
import { App } from './UI/App';

function AppProvided() {
  return (
    <AuthContextProvider>
      <App />
    </AuthContextProvider>
  );
}

export default withAuthentication(AppProvided);
