/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef } from 'react';

import styles from './SlideToggle.module.scss';

const SlideToggle = ({ value, onChange, className, rightToLeft, children }) => {
  const elementRef = useRef(null);
  const handleClick = () => {
    onChange?.(!value);
    elementRef.current?.blur();
  };

  return (
    <button ref={elementRef} onClick={handleClick} type="button" className={styles.slide_toggle}>
      <div
        className={`
        ${styles.track}
        ${rightToLeft && styles.right_to_left}
        ${value && 'SlideToggle_track_active'}
        ${className}
      `}
      >
        <div className={`${styles.thumb} SlideToggle_thumb`} />
      </div>
      {children}
    </button>
  );
};

export default SlideToggle;
