import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { getDataAPI, putDataAPI } from '../../Helpers/FetchAPI';
import useUser from '../../Hooks/useUser';

export const CreditsContext = createContext({
  credits: [],
  isLoading: false,
  error: null,
  getPendingCredits: () => {},
  handleUseCredit: () => {},
  handleRevokeCredit: () => {}
});

const CreditsContextProvider = ({ children }) => {
  const [credits, setCredits] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [callback, setCallback] = useState(true);

  const { currentUser } = useUser();

  const getCredits = useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await getDataAPI(`/credit/user/${currentUser._id}`);

      setCredits(res.data);
      setCallback(false);
      setIsLoading(false);
    } catch (err) {
      setError(err);
      setIsLoading(false);
    }
  }, [currentUser, callback]);

  const getPendingCredits = useCallback(
    async (callbackFN) => {
      await getDataAPI(`/credit/pendingcredits/${currentUser._id}`).then((res) => {
        callbackFN(res.data);
      });
    },
    [currentUser]
  );

  const handleUseCredit = useCallback(
    async (params) => {
      await putDataAPI(`/credit/usecredit/${currentUser._id}`, params);
      setCallback(() => { return !callback })
    },
    [currentUser, callback]
  );

  const handleRevokeCredit = useCallback(async (params) => {
    await putDataAPI(`/credit/revokecredit`, params);
  }, []);

  useEffect(() => {
    let isActive = true;

    if (isActive) {
      if (currentUser || callback) {
        getCredits();
      }
    }

    return () => {
      isActive = false;
    };
  }, [callback, currentUser, getCredits]);

  const value = useMemo(() => {
    return {
      credits,
      isLoading,
      error,
      getPendingCredits,
      handleUseCredit,
      handleRevokeCredit
    };
  }, [credits, isLoading, error, getPendingCredits, handleUseCredit, handleRevokeCredit]);
  return <CreditsContext.Provider value={value}>{children}</CreditsContext.Provider>;
};

export default CreditsContextProvider;
