/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { Box, Button, Divider, Grid, TextField, Typography } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import * as ReactDOM from 'react-dom';
import Modal from '../../Modal';
import useQuill from '../../../Hooks/useQuill';

const EditLessn = ({ setSnack, item, close, titleInfo }) => {
  const queryClient = useQueryClient();
  const [body, setBody] = useState(item.content);
  const { ref: quillRef, adminModules: modules, theme } = useQuill();

  const NotificationBox = (props) => {
    return ReactDOM.createPortal(props.children, document.getElementById('root'));
  };

  const validationSchema = Yup.object().shape({
    lessonTitle: Yup.string().required('Title is required')
  });

  const updateWorkBookItemMutation = useMutation(
    (params) => {
      return axios.put(`${process.env.REACT_APP_API_URL}/workbookItem/update-workbookitem/${item._id}`, params);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('sectionItems');
        queryClient.invalidateQueries('bookSection');
        setBody('');
        close();
        setSnack(true);
      },
      onError: (err) => {
        alert(err.message);
      }
    }
  );

  const saveWorkBookItemMutation = useMutation(
    (params) => {
      return axios.put(`${process.env.REACT_APP_API_URL}/workbookItem/update-workbookitem/${item._id}`, params);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('sectionItems');
        queryClient.invalidateQueries('bookSection');

        setSnack(true);
      },
      onError: (err) => {
        alert(err.message);
      }
    }
  );

  const formik = useFormik({
    initialValues: {
      lessonTitle: item.title,
      lessonDescription: item.description,
      type: 'Lesson'
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (fields, { close }) => {
      const params = {
        title: fields.lessonTitle,
        description: fields.lessonDescription,
        content: body,
        type: fields.type
      };
      updateWorkBookItemMutation.mutate(params);
    }
  });

  const handleSaving = () => {
    const fields = formik.values;
    const params = {
      title: fields.lessonTitle,
      description: fields.lessonDescription,
      content: body,
      type: fields.type
    };

    saveWorkBookItemMutation.mutate(params);
  };

  return (
    <Modal variant="medium">
      <div
        style={{
          overflowY: 'auto',
          height: '100%',
          width: '100%'
        }}
      >
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <Box className="modal-wrap" style={{ display: 'flex', flexDirection: 'column' }}>
              <Box className="modal-header" style={{ maxHeight: 'inherit' }}>
                <Typography variant="h2"> {titleInfo.title} </Typography>
                <Typography variant="body1"> {titleInfo.description} </Typography>
                <Divider style={{ margin: '20px 0' }} />
                <Grid container spacing={3} direction="row" justifyContent="space-between" alignItems="center">
                  <Grid item xs={12} sm={6}>
                    <label htmlFor="file" className="label">
                      Title*
                    </label>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="lessonTitle"
                      name="lessonTitle"
                      // label="Section Title"
                      placeholder="Type here..."
                      value={formik.values.lessonTitle}
                      onChange={formik.handleChange}
                      error={formik.touched.lessonTitle && Boolean(formik.errors.lessonTitle)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <label htmlFor="file" className="label">
                      Description
                    </label>
                    <TextField
                      margin="normal"
                      fullWidth
                      id="lessonDescription"
                      name="lessonDescription"
                      // label="Section Description"
                      placeholder="Type here..."
                      value={formik.values.lessonDescription}
                      onChange={formik.handleChange}
                      error={formik.touched.lessonDescription && Boolean(formik.errors.lessonDescription)}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Divider style={{ margin: '20px 0' }} />

              <Box className="modal-body" style={{}}>
                <Grid style={{ marginTop: '20px' }}>
                  <label htmlFor="file" className="label">
                    Content*
                  </label>
                  <ReactQuill
                    ref={quillRef}
                    style={{ maxHeight: '400px', marginTop: '14px', borderRadius: '4px' }}
                    value={body}
                    modules={modules}
                    theme={theme}
                    onChange={setBody}
                    placeholder="Type here..."
                  />
                </Grid>
              </Box>
              <Box className="modal-footer" style={{ width: 'inherit', maxHeight: 'inherit' }}>
                <Divider style={{ margin: '20px 0' }} />
                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justifyContent="flex-end"
                  style={{ marginTop: '0' }}
                  alignItems="center"
                >
                  <Grid item align="right">
                    <Button className="buttonLink outline red" onClick={close}>
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item align="center">
                    <Button className="buttonLink outline" type="button" onClick={handleSaving}>
                      Save
                    </Button>
                  </Grid>
                  <Grid item align="right">
                    <Button className="buttonLink" type="submit">
                      Save and Exit
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </form>
        </FormikProvider>
      </div>
    </Modal>
  );
};

const ImageUpload = (file) =>
  axios.post(`${process.env.REACT_APP_API_URL}/workbookItem/uploads`, file).then((res) => res.data);

export default EditLessn;
