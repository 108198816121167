import axios from 'axios';
import { Auth } from 'aws-amplify';

export const API_URL = process.env.REACT_APP_API_URL;

export const getDataAPI = async (url) => {
  const {
    accessToken: { jwtToken: token }
  } = await Auth.currentSession();

  const res = await axios.get(`${API_URL}${url}`, {
    headers: { Authorization: token }
  });

  return res;
};

export const postDataAPI = async (url, post) => {
  const {
    accessToken: { jwtToken: token }
  } = await Auth.currentSession();
  const res = await axios.post(`${API_URL}${url}`, post, {
    headers: { Authorization: token }
  });

  return res;
};

export const putDataAPI = async (url, post) => {
  const {
    accessToken: { jwtToken: token }
  } = await Auth.currentSession();
  const res = await axios.put(`${API_URL}${url}`, post, {
    headers: { Authorization: token }
  });

  return res;
};

export const patchDataAPI = async (url, post) => {
  const {
    accessToken: { jwtToken: token }
  } = await Auth.currentSession();
  const res = await axios.patch(`${API_URL}${url}`, post, {
    headers: { Authorization: token }
  });

  return res;
};

export const deleteDataAPI = async (url) => {
  const {
    accessToken: { jwtToken: token }
  } = await Auth.currentSession();
  const res = await axios.delete(`${API_URL}${url}`, {
    headers: { Authorization: token }
  });

  return res;
};
