import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { getDataAPI, postDataAPI, putDataAPI, deleteDataAPI } from '../../Helpers/FetchAPI';
import useUser from '../../Hooks/useUser';

export const NotebooksContext = createContext({
  notebooks: [],
  journals: [],
  isLoading: false,
  error: null,
  addNewEntry: () => {},
  updateEntry: () => {},
  deleteEntry: () => {},
  addNewJournal: () => {}
});

const NotebooksContextProvider = ({ children }) => {
  const [notebooks, setNotebooks] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [callback, setCallback] = useState(true);

  const { currentUser: userData } = useUser();

  const getNotebooks = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await getDataAPI(`/api/user/notebooks/${userData._id}`);

      setNotebooks(res.data.data);
      setIsLoading(false);
      setCallback(false);
    } catch (err) {
      setError(err);
      setIsLoading(false);
    }
  }, [userData]);

  const addNewEntry = useCallback(async ({ workbookId, userId, entry, creditId }, entryCallback) => {
    try {
      await postDataAPI('/api/notebook', {
        workbookId,
        userId,
        entry,
        creditId
      });
      setCallback(true);
      if (entryCallback) {
        entryCallback();
      }
    } catch (err) {
      setError(err);
    }
  }, []);

  const addNewJournal = useCallback(async ({ userId, entry, title }, entryCallback) => {
    try {
      await postDataAPI('/api/journal', {
        userId,
        entry,
        title
      });
      setCallback(true);
      if (entryCallback) {
        entryCallback();
      }
    } catch (err) {
      setError(err);
    }
  }, []);

  const updateEntry = useCallback(async ({ _id, notebookId, content }, entryCallback) => {
    try {
      await putDataAPI(`/api/notebook/${_id}/entry`, {
        entry: content
      });

      const result = await getDataAPI(`/api/notebook/${notebookId}`).then((res) => {
        return res.data.data;
      });

      setNotebooks((prev) => [
        ...prev.map((notebook) => {
          if (notebook._id === result._id) {
            notebook.entries = result.entries;
            notebook.updatedAt = result.updatedAt;
          }

          return notebook;
        })
      ]);

      if (entryCallback) {
        entryCallback();
      }
    } catch (err) {
      setError(err);
    }
  }, []);

  const deleteEntry = useCallback(async ({ _id, notebookId }, entryCallback) => {
    try {
      await deleteDataAPI(`/api/notebook/${_id}/entry`);

      const result = await getDataAPI(`/api/notebook/${notebookId}`).then((res) => {
        return res.data.data;
      });

      setNotebooks((prev) => [
        ...prev.map((notebook) => {
          if (notebook._id === result._id) {
            notebook.entries = result.entries;
            notebook.updatedAt = result.updatedAt;
          }

          return notebook;
        })
      ]);

      if (entryCallback) {
        entryCallback();
      }
    } catch (err) {
      setError(err);
    }
  }, []);

  const journals = useMemo(() => notebooks.filter((item) => item.type === 'journal'), [notebooks]);

  useEffect(() => {
    let isActive = true;

    if (isActive) {
      if (userData || callback) {
        getNotebooks();
      }
    }

    return () => {
      isActive = false;
    };
  }, [getNotebooks, userData, callback]);

  const value = useMemo(() => {
    return {
      notebooks: notebooks.filter((item) => item.type === 'notes'),
      error,
      isLoading,
      addNewEntry,
      updateEntry,
      deleteEntry,
      addNewJournal,
      journals
    };
  }, [notebooks, isLoading, error, addNewEntry, updateEntry, deleteEntry, addNewJournal, journals]);
  return <NotebooksContext.Provider value={value}>{children}</NotebooksContext.Provider>;
};

export default NotebooksContextProvider;
