import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './Button.module.scss';

const Button = ({ children, outline, onClick, disabled, type, variant, link, className, state }) => {
  if (link) {
    return (
      <Link
        to={link}
        className={`${styles.button} ${outline && styles.outline} ${styles[variant]} ${className}`}
        state={state}
        onClick={onClick}
      >
        {children}
      </Link>
    );
  }

  return (
    <button
      className={`${styles.button} ${styles[variant]} ${outline && styles.outline} ${className}`}
      onClick={onClick}
      disabled={disabled}
      type={type === 'submit' ? 'submit' : 'button'}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  outline: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'submit']),
  variant: PropTypes.oneOf(['primary', 'secondary', 'warning', 'default']),
  link: PropTypes.string,
  className: PropTypes.string
};

export default Button;
