export const API_URL = process.env.REACT_APP_API_URL;

export const SHIPPO_TOKEN = process.env.REACT_APP_SHIPPO_TOKEN;

export const LOCALGROUPSTEXT = 'Local Groups are facilicated by a Volunteer Leader and attendance is free.';

export const ONLINEGROUPSTEXT = 'Online Groups are facilicated by a Certified Leader and attendance is fee-based.';
export const DAYS = {
  '': [
    { label: 'Sunday', value: 'Sunday' },
    { label: 'Monday', value: 'Monday' },
    { label: 'Tuesday', value: 'Tuesday' },
    { label: 'Wednesday', value: 'Wednesday' },
    { label: 'Thursday', value: 'Thursday' },
    { label: 'Friday', value: 'Friday' },
    { label: 'Saturday', value: 'Saturday' }
  ]
};

export const FREQUENCIES = {
  '': [
    { label: 'Daily', value: 'Daily' },
    { label: 'Weekly', value: 'Weekly' },
    { label: 'Biweekly', value: 'Biweekly' },
    { label: 'Monthly', value: 'Monthly' },
    { label: 'Quarterly', value: 'Quarterly' },
    { label: 'Yearly', value: 'Yearly' }
  ]
};
export const TIMEZONES = {
  'North America': [
    { label: 'Eastern Standard Time (EST)', value: 'America/New_York' },
    { label: 'Pacific Standard Time (PST)', value: 'America/Los_Angeles' },
    { label: 'Central Standard Time (CST)', value: 'America/Chicago' },
    { label: 'Mountain Standard Time (MST)', value: 'America/Denver' },
    { label: 'Alaska Standard Time (AKST)', value: 'America/Anchorage' },
    { label: 'Hawaii-Aleutian Standard Time (HAST)', value: 'Pacific/Honolulu' }
  ],
  'South America': [
    { label: 'Brasília Time (BRT)', value: 'America/Sao_Paulo' },
    { label: 'Argentina Time (ART)', value: 'America/Argentina/Buenos_Aires' },
    { label: 'Colombia Time (COT)', value: 'America/Bogota' }
  ],
  Europe: [
    { label: 'Central European Time (CET)', value: 'Europe/Paris' },
    { label: 'British Summer Time (BST)', value: 'Europe/London' },
    { label: 'Central European Summer Time (CEST)', value: 'Europe/Berlin' },
    { label: 'Eastern European Time (EET)', value: 'Europe/Bucharest' },
    { label: 'Moscow Time (MSK)', value: 'Europe/Moscow' }
  ],
  Asia: [
    { label: 'India Standard Time (IST)', value: 'Asia/Kolkata' },
    { label: 'Japan Standard Time (JST)', value: 'Asia/Tokyo' },
    { label: 'China Standard Time (CST)', value: 'Asia/Shanghai' },
    { label: 'Korea Standard Time (KST)', value: 'Asia/Seoul' }
  ],
  Africa: [
    { label: 'South Africa Standard Time (SAST)', value: 'Africa/Johannesburg' },
    { label: 'East Africa Time (EAT)', value: 'Africa/Nairobi' },
    { label: 'West Africa Time (WAT)', value: 'Africa/Lagos' }
  ],
  'Australia and Oceania': [
    { label: 'Australian Eastern Standard Time (AEST)', value: 'Australia/Sydney' },
    { label: 'New Zealand Standard Time (NZST)', value: 'Pacific/Auckland' },
    { label: 'Australian Western Standard Time (AWST)', value: 'Australia/Perth' }
  ],
  'Middle East': [
    { label: 'Gulf Standard Time (GST)', value: 'Asia/Dubai' },
    { label: 'Israel Standard Time (IST)', value: 'Asia/Jerusalem' }
  ],
  Global: [{ label: 'Coordinated Universal Time (UTC)', value: 'Etc/UTC' }]
};

export const TIMEZONEMAPPING = {
  'America/New_York': 'Eastern Standard Time (EST)',
  'America/Los_Angeles': 'Pacific Standard Time (PST)',
  'America/Chicago': 'Central Standard Time (CST)',
  'America/Denver': 'Mountain Standard Time (MST)',
  'America/Anchorage': 'Alaska Standard Time (AKST)',
  'Pacific/Honolulu': 'Hawaii-Aleutian Standard Time (HAST)',
  'America/Sao_Paulo': 'Brasília Time (BRT)',
  'America/Argentina/Buenos_Aires': 'Argentina Time (ART)',
  'America/Bogota': 'Colombia Time (COT)',
  'Europe/Paris': 'Central European Time (CET)',
  'Europe/London': 'British Summer Time (BST)',
  'Europe/Berlin': 'Central European Summer Time (CEST)',
  'Europe/Bucharest': 'Eastern European Time (EET)',
  'Europe/Moscow': 'Moscow Time (MSK)',
  'Asia/Kolkata': 'India Standard Time (IST)',
  'Asia/Tokyo': 'Japan Standard Time (JST)',
  'Asia/Shanghai': 'China Standard Time (CST)',
  'Asia/Seoul': 'Korea Standard Time (KST)',
  'Africa/Johannesburg': 'South Africa Standard Time (SAST)',
  'Africa/Nairobi': 'East Africa Time (EAT)',
  'Africa/Lagos': 'West Africa Time (WAT)',
  'Australia/Sydney': 'Australian Eastern Standard Time (AEST)',
  'Pacific/Auckland': 'New Zealand Standard Time (NZST)',
  'Australia/Perth': 'Australian Western Standard Time (AWST)',
  'Asia/Dubai': 'Gulf Standard Time (GST)',
  'Asia/Jerusalem': 'Israel Standard Time (IST)',
  'Etc/UTC': 'Coordinated Universal Time (UTC)'
};
