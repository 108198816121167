import Link from '@mui/material/Link';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { typedRoute } from '../../utils/typedRoute';

export const RouterLinkBehavior = forwardRef(({ href, ...props }, ref) => (
  <RouterLink ref={ref} to={href} {...props} role={undefined} />
));

const PDLink = forwardRef(({ href, path, ...props }, ref) => (
  <Link component={RouterLinkBehavior} ref={ref} href={path ? typedRoute(path) : href} underline="hover" {...props} />
));

export default PDLink;
