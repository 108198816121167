import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import ProgressBar from '../ProgressBar';
import styles from './FundItem.module.scss';
import errorThumbnail from '../../images/pd-resource-square.png';
import Modal from '../Modal';
import { Divider, Grid } from '@mui/material';
import { formatCurrency } from '../../Helpers/Functions';

const FundItem = ({
  title,
  description,
  type,
  src,
  value,
  maximumValue,
  price,
  className,
  variant,
  onClick,
  onView,
  row,
  discount,
  selected
}) => {
  const [hover, setHover] = useState(false);
  const [modal, setModal] = useState(false);
  const percentage = useMemo(() => {
    if (maximumValue === 0) return '0%';
    else return `${((value * 100) / maximumValue).toFixed(0)}%`;
  }, [value, maximumValue]);

  const handleHover = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  if (variant === 'donation') {
    return (
      <div className={`${styles.fund} ${className}`} onMouseEnter={handleHover} onMouseLeave={handleMouseLeave}>
        {modal && (
          <Modal
            variant="smallM"
            onClose={() => {
              setModal(false);
              setHover(false);
            }}
          >
            <div className={styles.fund_modal}>
              <div className={styles.fund_header}>
                <h2>{title}</h2>
              </div>
              <Divider />
              <div className={styles.fund_container}>
                <div className={styles.fund_block}>
                  <div className={styles.fund_modalImage}>
                    <img
                      src={src}
                      alt={title}
                      onError={(event) => {
                        event.target.src = errorThumbnail; // set a default image in case of error
                      }}
                    />
                  </div>
                  <div className={styles.fund_progress}>
                    <ProgressBar
                      value={value}
                      maximumValue={maximumValue}
                      className={styles.fund_line}
                      showPercentage={false}
                    />
                    <span className={styles.fund_percentage}>
                      {value} out of {maximumValue} are Funded
                    </span>
                  </div>
                </div>
                <span className={styles.fund_description}>{description}</span>
              </div>
              <div className={styles.modal_buttons}>
                <Button variant="warning" onClick={onClick}>
                  {selected ? 'Unselect' : 'Give'}
                </Button>
              </div>
            </div>
          </Modal>
        )}
        {hover && (
          <div className={styles.fund_buttons}>
            <Button
              variant="warning"
              outline
              onClick={() => {
                setModal(true);
                setHover(false);
              }}
            >
              Info
            </Button>
            <Button variant="warning" onClick={onClick}>
              {selected ? 'Unselect' : 'Give'}
            </Button>
          </div>
        )}
        <div className={styles.fund_image}>
          <img
            src={src}
            alt={title}
            onError={(event) => {
              event.target.src = errorThumbnail; // set a default image in case of error
            }}
          />
        </div>
        <div className={styles.fund_content}>
          <div className={styles.content}>
            <span className={styles.fund_type}>{type}</span>
            <span className={styles.fund_title}>{title}</span>
          </div>
        </div>
        <div className={styles.fund_progress}>
          <ProgressBar value={value} maximumValue={maximumValue} className={styles.fund_line} showPercentage={false} />
          <span className={styles.fund_percentage}>{value} Funded</span>
        </div>
      </div>
    );
  }

  return (
    <Grid container className={styles.fund}>
      <Grid item lg={row ? 4 : 12} sm={12} xs={12}>
        <div className={styles.fund_image}>
          <img
            src={src}
            alt={title}
            onError={(event) => {
              event.target.src = errorThumbnail; // set a default image in case of error
            }}
          />
        </div>
      </Grid>
      <Grid item lg={row ? 7 : 12}>
        <div className={styles.col}>
          <div className={styles.fund_content}>
            <div className={styles.details}>
              <span className={styles.fund_type}>{type}</span>
              <span className={styles.fund_title}>{title}</span>
            </div>
          </div>
          <div style={{ width: '100%', display: 'flex', marginTop: 'auto' }}>
            {type !== 'group' && type !== 'bundle' && (
              <div
                className={styles.fund_price}
                style={{
                  textDecoration: discount?.less > 0 ? 'line-through' : 'none'
                }}
              >
                ${price}
              </div>
            )}
            {discount?.less > 0 && <div className={styles.fund_price}> ${discount.less}</div>}
          </div>
          <div className={styles.fund_ecommerce}>
            <Button outline link={onView}>
              Learn More
            </Button>
            <Button onClick={onClick}>{type === 'group' ? 'Join' : 'Add to Cart'}</Button>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

FundItem.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  src: PropTypes.string,
  value: PropTypes.number,
  maximumValue: PropTypes.number,
  className: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.oneOf(['donation', 'ecommerce'])
};

FundItem.defaultProps = {
  value: 0,
  maximumValue: 100,
  variant: 'donation'
};

export default FundItem;
