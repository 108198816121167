import React, { useEffect, Fragment, useState, useMemo } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { formatCapitalizeWord } from '../../Helpers/Functions';
import styles from './LayerList.module.scss';
import { useMedia } from 'react-use';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const LayerList = ({
  data,
  workbookTitle,
  onClick,
  activeId,
  setActiveId,
  showSection,
  setShowSection,
  linear,
  progress,
  preview,
  setCertification,
  setScore,
  disabled
}) => {
  const [initLoad, setInitLoad] = useState(true);
  const isBelow1024Screen = useMedia('(max-width: 1024px)');
  const [viewLayer, setViewLayer] = useState(false);

  const layerExpander = useMemo(() => {
    return isBelow1024Screen && viewLayer;
  }, [isBelow1024Screen && viewLayer]);

  const handleSectionClick = (id) => {
    const currentIndex = data.findIndex((item) => item?._id === showSection);
    const checkIndex = data.findIndex((item) => item?._id === id);
    const completed = progress?.maximumValue === progress?.value;
    const firstCompleteChild = data[checkIndex]?.items.filter((child) => child.item_id.completed)[0];

    if (linear) {
      if (!completed && id === 'outro') {
        return;
      }

      if (completed && id === 'certificate') {
        onClick('certificate');
        setActiveId('certificate');
        setShowSection('certificate');
        return;
      }

      if (completed && id === 'outro') {
        setShowSection('outro');
        onClick('outro');
        setActiveId('outro');
        return;
      } else {
        const firstIncompleteChild = data[checkIndex]?.items.find((child) => !child.item_id.completed);
        switch (true) {
          // check if section is before then allow section selection
          case currentIndex > checkIndex:
            setScore(false);
            setShowSection(id);
            onClick(firstCompleteChild);
            setActiveId(firstCompleteChild?._id);
            return;
          case activeId === 'intro' && showSection === 'intro' && checkIndex === 0:
            if (data[checkIndex]?.completed) {
              setShowSection(id);
              onClick(firstCompleteChild);
              setActiveId(firstCompleteChild?._id);
              return;
            }

            setShowSection(id);
            onClick(firstIncompleteChild);
            setActiveId(firstIncompleteChild?._id);
            return;
          case (currentIndex === -1 && data[checkIndex - 1]?.completed) ||
            (data[currentIndex]?.completed && currentIndex + 1 === checkIndex && !completed):
            setShowSection(id);
            onClick(firstIncompleteChild);
            setActiveId(firstIncompleteChild?._id);
            return;
          case currentIndex <= checkIndex && completed:
            setShowSection(id);
            onClick(firstCompleteChild);
            setActiveId(firstCompleteChild?._id);
            return;
          default:
            return;
        }
      }
    } else {
      if (preview) {
        if (id === 'outro') {
          setShowSection('outro');
          onClick('outro');
          setActiveId('outro');
          return;
        }

        const firstIncompleteChild = data[checkIndex]?.items.find((child) => !child.item_id.completed);
        setShowSection((prev) => (prev === id ? prev : id));
        onClick(firstIncompleteChild);
        setActiveId(firstIncompleteChild?._id);
        return;
      }

      if (id === 'certificate' && completed) {
        onClick('certificate');
        setActiveId('certificate');
        setShowSection('certificate');
        return;
      }

      if (data[checkIndex]?.completed) {
        const firstCompleteChild = data[checkIndex]?.items.filter((child) => child.item_id.completed)[0];
        setShowSection((prev) => (prev === id ? prev : id));
        onClick(firstCompleteChild);
        setActiveId(firstCompleteChild?._id);
        return;
      }

      if (id === 'outro') {
        setShowSection('outro');
        onClick('outro');
        setActiveId('outro');
        return;
      }

      const firstIncompleteChild = data[checkIndex]?.items.find((child) => !child.item_id.completed);
      setShowSection((prev) => (prev === id ? prev : id));
      onClick(firstIncompleteChild);
      setActiveId(firstIncompleteChild?._id);
      return;
    }
  };

  const handleChildClick = (content) => {
    const currentSection = data.find((item) => item?._id === showSection).items;
    const currentChild = currentSection.find((item) => item?._id === activeId);
    const currentIndex = currentSection.findIndex((item) => item?._id === activeId);
    const nextChild = currentSection.find((item) => item?._id === content?._id);
    const nextIndex = currentSection.findIndex((item) => item?._id === content?._id);
    if (linear && content !== 'certificate') {
      switch (true) {
        case nextChild.item_id.completed || (currentChild.item_id.completed && currentIndex + 1 === nextIndex):
          onClick(nextChild);
          setActiveId(nextChild?._id);
          setCertification('');
          return;
        default:
          return;
      }
    } else {
      if (onClick) {
        onClick(content);
      }

      if (content === 'certificate' || content === 'intro' || content === 'outro') {
        setActiveId(content);
        return;
      } else {
        setActiveId(content?._id);
        return;
      }
    }
  };

  useEffect(() => {
    let isActive = true;

    if (isActive) {
      const firstIncompleteSection = data.find((section) => !section.completed);
      const firstIncompleteChild = firstIncompleteSection?.items.find((child) => !child.item_id.completed);
      if (preview && initLoad) {
        setInitLoad(false);
        if (onClick) {
          onClick('intro');
          setActiveId('intro');
          setShowSection('intro');
          return;
        }
      } else {
        if (progress) {
          const completed = progress?.maximumValue === progress?.value;
          if (progress?.value === 0) {
            onClick('intro');
            setActiveId('intro');
            setShowSection('intro');
            return;
          }

          if (completed) {
            onClick('certificate');
            setActiveId('certificate');
            setShowSection('certificate');
            return;
          } else {
            setShowSection(firstIncompleteSection?._id);
            setActiveId(firstIncompleteChild?._id);
            setInitLoad(false);
            if (onClick) {
              onClick(firstIncompleteChild);
              return;
            }
          }
        }
      }
    }

    return () => {
      isActive = false;
    };
  }, []);

  return (
    <div className={`${styles.layerlist} ${layerExpander ? styles.expandMore : styles.expandLess}`}>
      <div
        className={styles.overlay}
        style={{
          visibility: disabled ? 'visible' : 'hidden',
          opacity: disabled ? '1' : '0'
        }}
      ></div>
      <div
        className={`${styles.layer_item} ${'intro' === showSection && styles.active}`}
        onClick={() => {
          if (layerExpander) return;
          if (setScore) {
            setScore(false);
          }
          onClick('intro');
          setActiveId('intro');
          setShowSection('intro');
        }}
      >
        <div className={styles.layer_item_group}>
          <span className={styles.layer_item_title}>
            {formatCapitalizeWord(workbookTitle)}
            {isBelow1024Screen && (
              <div
                className={styles.layer_drop}
                onClick={(e) => {
                  e.stopPropagation();
                  setViewLayer((prev) => !prev);
                }}
              >
                <ExpandMoreIcon />
              </div>
            )}
          </span>
        </div>
      </div>
      <div className={styles.layer_border}>
        <div className={styles.layer_line} />
      </div>
      {data.map((section, i) => (
        <Fragment key={section._id}>
          <div
            className={`${styles.layer_item} ${section._id === showSection && styles.active}`}
            onClick={() => handleSectionClick(section._id)}
          >
            {section.completed ? (
              <CheckCircleIcon
                sx={{
                  fill: '#00998c'
                }}
                fontSize="small"
              />
            ) : (
              <RadioButtonUncheckedIcon
                sx={{
                  fill: '#00998c'
                }}
                fontSize="small"
              />
            )}
            <div className={styles.layer_item_group}>
              <span className={styles.layer_item_title}>{section?.title}</span>
              {section?.description && <span className={styles.layer_item_description}>{section?.description}</span>}
            </div>
          </div>
          {showSection === section._id && (
            <ul className={styles.layer_childs}>
              {section.items.map((child) => (
                <li
                  key={child._id}
                  onClick={() => handleChildClick(child)}
                  className={`${styles.layer_child} ${activeId === child._id && styles.layer_active}`}
                >
                  {child.item_id.completed ? (
                    <CheckCircleIcon
                      sx={{
                        fill: activeId === child._id ? '#fff' : '#00998c'
                      }}
                      fontSize="small"
                    />
                  ) : (
                    <RadioButtonUncheckedIcon
                      sx={{
                        fill: activeId === child._id ? '#fff' : '#00998c'
                      }}
                      fontSize="small"
                    />
                  )}
                  {child.item_id.title}
                </li>
              ))}
            </ul>
          )}
          <div className={styles.layer_border}>
            <div className={styles.layer_line} />
          </div>
        </Fragment>
      ))}
      {progress && progress.value === progress.maximumValue && (
        <>
          <div
            className={`${styles.layer_item} ${'certificate' === showSection && styles.active}`}
            onClick={() => handleSectionClick('certificate')}
          >
            <div className={styles.layer_item_group}>
              <span className={styles.layer_item_title}>Certificate of Completion</span>
            </div>
          </div>
          <div className={styles.layer_border}>
            <div className={styles.layer_line} />
          </div>
        </>
      )}
      <div
        className={`${styles.layer_item} ${'outro' === showSection && styles.active}`}
        onClick={() => handleSectionClick('outro')}
      >
        <div className={styles.layer_item_group}>
          <span className={styles.layer_item_title}>Appendix</span>
        </div>
      </div>
    </div>
  );
};

export default LayerList;
