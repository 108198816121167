import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs, Typography } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import styles from './Menu.module.scss';
import { formatCapitalizeWord } from '../../Helpers/Functions';
import useAuth from '../../Hooks/useAuth';
import LanguageSelect from '../LanguageSelect';
import SlideToggle from '../SlideToggle';
import useUser from '../../Hooks/useUser';
import { postDataAPI } from '../../Helpers/FetchAPI';
import Button from '../Button';
import { Avatar } from '../Avatar';
import useUserCart from '../../Hooks/useUserCart';
import ProfileMenu from '../ProfileMenu';

const Menu = () => {
  const { pathname } = useLocation();
  const { signOut } = useAuth();
  const { currentUser: userData, userView, handleUserViewChange, impersonateEmail } = useUser();
  const navigate = useNavigate();
  const { totalItems } = useUserCart();

  const [libraryCrumb, setLibraryCrumb] = useState('');
  const { t } = useTranslation();

  const getpaths = useMemo(() => pathname.split('/').filter((item) => item !== ''), [pathname]);

  useEffect(() => {
    let isActive = true;

    if (isActive) {
      if (getpaths.includes('library') && getpaths.length === 2) {
        postDataAPI('/api/user/workbook', {
          credit_id: getpaths[1],
          user_id: userData?._id
        })
          .then((res) => setLibraryCrumb(res.data.title))
          .catch((err) => setLibraryCrumb(''));
        return;
      }
      setLibraryCrumb('');
    }

    return () => {
      isActive = false;
    };
  }, [getpaths, userData]);

  const lastBreadcrumb = useMemo(() => {
    return getpaths.map((item, i) => {
      const last = getpaths.length === i + 1;

      if (!last) {
        return (
          <Link
            color="inherit"
            to={item === 'profile' ? '/' : `/${item}`}
            className={styles.breadcrumb_item}
            key={`breadcrumd_${i}`}
          >
            {formatCapitalizeWord(t(item))}
          </Link>
        );
      }
      return (
        <Typography
          className={styles.breadcrumb_last}
          sx={{
            fontFamily: '1.6rem'
          }}
          key={`breadcrumd_${i}`}
        >
          {getpaths.includes('library') && getpaths.length === 2 ? libraryCrumb : formatCapitalizeWord(t(item))}
        </Typography>
      );
    });
  }, [getpaths, libraryCrumb, t]);

  return (
    <div className={styles.menu}>
      <div className={styles.menu_left}>
        <Breadcrumbs
          aria-label="breadcrumb"
          sx={{
            '& ol': {
              display: 'flex',
              alignItems: 'center'
            }
          }}
        >
          <Link color="inherit" to="/" className={styles.breadcrumb_item}>
            <HomeIcon fontSize="large" />
          </Link>
          {/* <Link color="inherit" to="/" className={styles.breadcrumb_item}> */}
          {/*  Dashboard */}
          {/* </Link> */}
          {lastBreadcrumb}
        </Breadcrumbs>
      </div>
      <div className={styles.menu_right}>
        {impersonateEmail === '' && userData?.role === 'admin' && (
          <SlideToggle value={userView} onChange={(value) => handleUserViewChange(value, navigate('/'))}>
            {t('userView')}
          </SlideToggle>
        )}
        <LanguageSelect />
        {/*{totalItems > 0 && (*/}
        {/*  <Link color="inherit" to="/cart" className={styles.cart}>*/}
        {/*    <ShoppingCartIcon className={styles.menu_cart} fontSize="large" />*/}
        {/*    <span className={styles.cart_number}>{totalItems}</span>*/}
        {/*  </Link>*/}
        {/*)}*/}
        <ProfileMenu />
      </div>
    </div>
  );
};
const MobileAdminToggle = () => {
  const { currentUser: userData, userView, handleUserViewChange, impersonateEmail } = useUser();
  const navigate = useNavigate();
  return (
    <li key={'admin'}>
      {impersonateEmail === '' && userData?.role === 'admin' && (
        <SlideToggle value={userView} onChange={(value) => handleUserViewChange(value, navigate('/'))}>
          User View
        </SlideToggle>
      )}
    </li>
  );
};

const MobileSignOut = () => {
  return <ProfileMenu />;
};
const MobileProfile = () => {
  const { totalItems } = useUserCart();

  return (
    <>
      {/*<li key={'carts'}>*/}
      {/*  <Button variant="primary" link={`/cart`} className={styles.menu_item}>*/}
      {/*    <ShoppingCartIcon className={styles.menu_carts} fontSize="large" />*/}
      {/*    <span className={styles.cart_number}>Cart ({totalItems})</span>*/}
      {/*  </Button>*/}
      {/*</li>*/}
    </>
  );
};

export default Menu;
export { Menu, MobileProfile, MobileAdminToggle, MobileSignOut };
