import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import useBackdrop from '../../Hooks/useBackdrop';

const BackdropLoader = () => {
  const { isVisible, message } = useBackdrop();
  return (
    <Backdrop
      sx={{
        color: (theme) => theme.palette.primary.main,
        zIndex: (theme) => theme.zIndex.drawer + 1,
        display: 'flex',
        flexDirection: 'column',
        gap: '2rem'
      }}
      open={isVisible}
    >
      <CircularProgress color="inherit" />
      {message && (
        <h1
          style={{
            color: '#fff'
          }}
        >
          {message}
        </h1>
      )}
    </Backdrop>
  );
};

export default BackdropLoader;
