import React from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Avatar } from '../Avatar';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PasswordIcon from '@mui/icons-material/Password';
import PersonIcon from '@mui/icons-material/Person';
import HistoryIcon from '@mui/icons-material/History';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';

import styles from './ProfileMenu.module.scss';
import useAuth from '../../Hooks/useAuth';
import useUser from '../../Hooks/useUser';
import { useNavigate } from 'react-router-dom';

const ProfileMenu = () => {
  const { signOut } = useAuth();
  const { currentUser: userData } = useUser();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [menuAnchor, setMenuAnchor] = React.useState(null);

  return (
    <div className={styles.profile}>
      <Button
        onClick={({ currentTarget }) => setMenuAnchor(currentTarget)}
        style={{
          fontFamily: 'inherit',
          fontSize: 'inherit',
          fontWeight: 400,
          gap: '1rem'
        }}
      >
        <Avatar src={userData?.avatar} name={userData?.name || 'A'} />
        {userData?.firstName && userData?.lastName ? `${userData.firstName} ${userData.lastName}` : userData.name}
      </Button>
      <Popover
        open={!!menuAnchor}
        anchorEl={menuAnchor}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <List
          sx={{
            width: '100%',
            maxWidth: 360,
            padding: '0.5rem 0',
            gap: '1rem',
            backgroundColor: '#fafafa'
          }}
        >
          <ListItem
            onClick={() => {
              navigate(`/profile/${userData._id}?q=account`);
              setMenuAnchor(null);
            }}
            sx={{
              fontSize: '1.3rem'
            }}
            className={styles.profile_menu}
          >
            <PersonIcon className={styles.logo_icon} fontSize="large" />
            {t('account')}
          </ListItem>
          <ListItem
            onClick={() => {
              navigate(`/profile/${userData._id}?q=update-password`);
              setMenuAnchor(null);
            }}
            sx={{
              fontSize: '1.3rem'
            }}
            className={styles.profile_menu}
          >
            <PasswordIcon className={styles.logo_icon} fontSize="large" />
            {t('update password')}
          </ListItem>
          {/*<ListItem*/}
          {/*  onClick={() => {*/}
          {/*    navigate(`/profile/${userData._id}?q=donations`);*/}
          {/*    setMenuAnchor(null);*/}
          {/*  }}*/}
          {/*  sx={{*/}
          {/*    fontSize: '1.3rem'*/}
          {/*  }}*/}
          {/*  className={styles.profile_menu}*/}
          {/*>*/}
          {/*  <VolunteerActivismIcon className={styles.logo_icon} fontSize="large" />*/}
          {/*  {t('donations')}*/}
          {/*</ListItem>*/}
          {/*<ListItem*/}
          {/*  onClick={() => {*/}
          {/*    navigate(`/profile/${userData._id}?q=orders`);*/}
          {/*    setMenuAnchor(null);*/}
          {/*  }}*/}
          {/*  sx={{*/}
          {/*    fontSize: '1.3rem'*/}
          {/*  }}*/}
          {/*  className={styles.profile_menu}*/}
          {/*>*/}
          {/*  <HistoryIcon className={styles.logo_icon} fontSize="large" />*/}
          {/*  {t('orders')}*/}
          {/*</ListItem>*/}
          <ListItem
            onClick={signOut}
            sx={{
              fontSize: '1.3rem'
            }}
            className={styles.profile_menu}
          >
            <ExitToAppIcon className={styles.logo_icon} fontSize="large" />
            {t('logout')}
          </ListItem>
        </List>
      </Popover>
    </div>
  );
};

export default ProfileMenu;
