/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import Title from '../Title';
import styles from './Modal.module.scss';

const Modal = ({ title, children, onClose, variant, prev }) => {
  return (
    <div className={styles.modal}>
      <div className={`${styles.modal_container} ${styles[variant]}`}>
        {prev && (
          <div className={styles.modal_prev} onClick={prev}>
            <ArrowBackIcon />
          </div>
        )}
        {onClose && (
          <div className={styles.modal_close} onClick={onClose}>
            <CloseIcon />
          </div>
        )}
        {!!title && <Title>{title}</Title>}
        {children}
      </div>
    </div>
  );
};

Modal.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'small', 'smallM', 'medium', 'large', 'transparent', 'tiny']),
  onClose: PropTypes.func,
  prev: PropTypes.func
};

Modal.defaultProps = {
  variant: 'small'
};

export default Modal;
