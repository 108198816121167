import React from 'react';
import { Visibility as VisibilityIcon, Close as CloseIcon } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import styles from './ImpersonateView.module.scss';
import useUser from '../../Hooks/useUser';

const ImpersonateView = ({ impersonateEmail, setImpersonationEmail }) => {
  const { handleUserViewChange } = useUser();
  const navigate = useNavigate();

  return (
    <div className={styles.impersonate_view}>
      <Typography
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '.5rem',
          fontFamily: 'inherit'
        }}
      >
        <VisibilityIcon /> You are currently viewing the dashboard as {impersonateEmail}.
      </Typography>
      <Button
        onClick={() => {
          setImpersonationEmail('');
          handleUserViewChange(false);
          navigate('/users');
        }}
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '.5rem',
          position: 'absolute',
          right: '10px',
          color: 'inherit'
        }}
      >
        <CloseIcon /> end
      </Button>
    </div>
  );
};

export default ImpersonateView;
