import { useEffect, useState } from 'react';
import en_json from './public/assets/locales/en/translation.json';
import es_json from './public/assets/locales/es/translation.json';

export function translator(str) {
  const i18nextLng_item = localStorage.getItem('i18nextLng');
  let selected;
  if (i18nextLng_item) {
    selected = localStorage.getItem('i18nextLng').substring(0, 2) || 'en';
  } else {
    selected = 'en';
  }
  return selLanguage(selected, str);
}

const selLanguage = (lang, word) => {
  switch (lang) {
    case 'en':
      return en_json[word] ? en_json[word] : '';
      break;
    case 'es':
      return es_json[word] ? es_json[word] : '';
      break;
  }
};
