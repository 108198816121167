import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import useAlertStore from '../../Hooks/useAlertStore';
import Button from '../Button';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertModal() {
  const { isOpen, closeAlert } = useAlertStore();

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeAlert}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>Cart</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Your first item has been successfully added to cart.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button outline onClick={closeAlert}>
          Close
        </Button>
        <Button link="/cart" onClick={closeAlert}>
          Go to cart
        </Button>
      </DialogActions>
    </Dialog>
  );
}
