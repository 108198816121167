import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

const Title = ({ children, onClick }) => {
  return (
    <Typography
      component="h1"
      variant="h1"
      gutterBottom
      textAlign="left"
      sx={{
        fontSize: '5rem',
        fontWeight: '400',
        color: 'var(--foreground)',
        lineHeight: '4.9rem',
        marginBottom: '1.5rem',
        fontFamily: 'Bebas Neue',
        cursor: onClick ? 'pointer' : 'default'
      }}
      onClick={onClick}
    >
      {children}
    </Typography>
  );
};

Title.propTypes = {
  children: PropTypes.node
};

export default Title;
