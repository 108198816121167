import { useQuery, useMutation, useQueryClient } from 'react-query';
import useUser from './useUser';
import { postDataAPI } from '../Helpers/FetchAPI';
import { useState } from 'react';

const useUserPayment = () => {
  const [isNewCard, setIsNewCard] = useState(false);
  const { getPaymentMethod } = useUser();
  const queryClient = useQueryClient();
  const { data: paymentMethodsData, isLoading: paymentMethodsLoading } = useQuery(
    'userPaymentMethods',
    getPaymentMethod,
    {
      staleTime: Infinity, // This means the data is considered fresh forever and won't refetch
      cacheTime: Infinity // This means the data is kept in the cache forever, unless manually invalidated
    }
  );

  const usePaymentMutation = useMutation(
    (params) => {
      if (params?.makeDefault) {
        return postDataAPI('/api/update-customer-payment-method', params);
      }
      return postDataAPI('/api/delete-payment-method', params);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('userPaymentMethods');
      },
      onError: (err) => {
        alert(err.message);
      }
    }
  );

  const removePaymentMethod = (paymentMethodId) => () => {
    usePaymentMutation.mutate({ paymentMethodId });
  };

  const handleDefaultPaymentMethod = (paymentMethodId) => () => {
    usePaymentMutation.mutate(
      { paymentMethodId, makeDefault: true },
      {
        onSettled: () => {
          setIsNewCard(false);
        }
      }
    );
  };

  return {
    paymentMethodsData,
    paymentMethodsLoading,
    handleDefaultPaymentMethod,
    removePaymentMethod,
    setIsNewCard,
    isNewCard
  };
};

export default useUserPayment;
