import React, { useState } from 'react';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { Divider } from '@mui/material';
import styles from './Accordion.module.scss';

const Accordion = ({ items, controlled }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleAccordionClick = (index) => () => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  if (controlled >= 0) {
    return (
      <div className={styles.accordion_container}>
        {items.map((item, index) => {
          return (
            <div className={styles.accordion_item} key={`accordion-${index}`}>
              <div
                className={`${styles.accordion_header} ${index <= controlled && styles.active}`}
                onClick={handleAccordionClick(index)}
              >
                <h3 className={styles.accordion_label}>{item.title}</h3>

                <div className={`${styles.accordion_icon} ${index <= controlled && styles.active}`}>
                  <ChevronRight />
                </div>
              </div>
              <Divider />
              {index <= controlled && item.content}
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div className={styles.accordion_container}>
      {items.map((item, index) => {
        const active = index === activeIndex;

        return (
          <div className={styles.accordion_item} key={`accordion-${index}`}>
            <div
              className={`${styles.accordion_header} ${active && styles.active}`}
              onClick={handleAccordionClick(index)}
            >
              <h3 className={styles.accordion_label}>{item.title}</h3>

              <div className={`${styles.accordion_icon} ${active && styles.active}`}>
                <ChevronRight />
              </div>
            </div>
            <Divider />
            {active && item.content}
          </div>
        );
      })}
    </div>
  );
};

export default Accordion;
