/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import * as Yup from 'yup';
import { useFormik, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import { useDropzone } from 'react-dropzone';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import ReactQuill, { Quill } from 'react-quill';
import { Divider } from '../../components/atoms';
import Thumb from './Thumb';
import useQuill from '../../Hooks/useQuill';

import htmlEditButton from 'quill-html-edit-button';
import Title from '../../components/Title';

Quill.register('modules/htmlEditButton', htmlEditButton);

const CreateWorkbook = () => {
  const history = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const { ref: quillRef, adminModules, theme } = useQuill();
  const { ref: introQuillRef, adminModules: introModules } = useQuill();
  const { ref: outroQuillRef, adminModules: outroModules } = useQuill();
  const [body, setBody] = useState();
  const [bodyIntro, setBodyIntro] = useState();
  const [bodyOutro, setBodyOutro] = useState();

  const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(false);
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required')
    // email: Yup.string()
    //     .required('Email is required')
    //     .email('Email is invalid'),
    // password: Yup.string()
    //     .matches(
    //         /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
    //         "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    //     ),
    // role: Yup.string()
    //     .required('Role is required'),
  });

  const formik = useFormik({
    initialValues: {
      title: '',
      sku: '',
      scoring: true,
      description: '',
      colorMain: '#000000',
      colorLightShade: '#000000',
      colorDarkShade: '#000000',
      colorBackground: '#000000',
      file_cover: [],
      file_header: [],
      intro: '',
      outro: '',
      price: null
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append('title', values.title);
      formData.append('sku', values.sku);
      formData.append('scoring', values.scoring);
      formData.append('description', body);
      formData.append('colorMain', values.colorMain);
      formData.append('colorLightShade', values.colorLightShade);
      formData.append('colorDarkShade', values.colorDarkShade);
      formData.append('colorBackground', values.colorBackground);
      formData.append('file_cover', values.file_cover[0]);
      formData.append('file_header', values.file_header[0]);
      formData.append('disabled', false);
      formData.append('intro', bodyIntro);
      formData.append('outro', bodyOutro);
      formData.append('price', +values.price);

      if (!formik.values.file_cover || !formik.values.file_header) {
        setErrorMessage(true);
      } else {
        const config = {
          headers: {
            'content-type': 'multipart/form-data'
          },
          maxContentLength: 10000000,
          maxBodyLength: 10000000
        };

        axios
          .post(`${process.env.REACT_APP_API_URL}/workbook/create-workbook`, formData, config)
          .then((res) => {
            if (res.status === 200) {
              console.log(res);
              history(`/workbook/` + res.data.workbookId);
            } else Promise.reject();
          })
          .catch((err) => alert(err));
      }
    }
  });

  const UploadComponent = () => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      accept: { 'image/*': [] },
      onDrop: (acceptedFiles) => {
        formik.setFieldValue('file_cover', acceptedFiles);
      }
    });
    return (
      <div>
        {}
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} multiple={false} />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <p>Drag 'n' drop some files here, or click to select files</p>
          )}
        </div>
      </div>
    );
  };

  const UploadComponentHeader = () => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      accept: { 'image/*': [] },
      onDrop: (acceptedFiles) => {
        formik.setFieldValue('file_header', acceptedFiles);
      }
    });
    return (
      <div>
        {}
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} multiple={false} />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <p>Drag 'n' drop some files here, or click to select files</p>
          )}
        </div>
      </div>
    );
  };

  return (
    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert severity="error" sx={{ width: '100%' }}>
          You must add a header and a cover image!
        </Alert>
      </Snackbar>
      <div className="workbookForm" style={{ minHeight: '55vh' }}>
        <Typography color="primary.neutral" component="h1" variant="h1" sx={{ mb: 2, fontSize: '3.5rem' }}>
          Create New Resource
        </Typography>
        <Divider />
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={3} direction="row" justifyContent="space-between" alignItems="center">
              <Grid item xs={12} sm={10}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="title"
                  name="title"
                  label={
                    <Typography component="span" style={{ fontSize: '1.4rem' }}>
                      {' '}
                      Title{' '}
                    </Typography>
                  }
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  helperText={formik.touched.title && formik.errors.title}
                />
              </Grid>

              <Grid item xs={12} sm={2}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formik.values.scoring}
                      onChange={formik.handleChange}
                      // error={formik.touched.scoring && Boolean(formik.errors.scoring)}
                      name="scoring"
                    />
                  }
                  label={formik.values.scoring ? 'Linear' : 'Non-Linear'}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="sku"
                  name="sku"
                  label={
                    <Typography component="span" style={{ fontSize: '1.4rem' }}>
                      {' '}
                      SKU{' '}
                    </Typography>
                  }
                  value={formik.values.sku}
                  onChange={formik.handleChange}
                  error={formik.touched.sku && Boolean(formik.errors.sku)}
                  helperText={formik.touched.sku && formik.errors.sku}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="price"
                  name="price"
                  label={
                    <Typography component="span" style={{ fontSize: '1.4rem' }}>
                      {' '}
                      Price{' '}
                    </Typography>
                  }
                  value={formik.values.price}
                  onChange={formik.handleChange}
                  error={formik.touched.price && Boolean(formik.errors.price)}
                  helperText={formik.touched.price && formik.errors.price}
                />
              </Grid>
            </Grid>
            {/* <TextField */}
            {/*    margin="normal" */}
            {/*    required */}
            {/*    fullWidth */}
            {/*    multiline */}
            {/*    rows={4} */}
            {/*    id="description" */}
            {/*    name="description" */}
            {/*    label="Description" */}
            {/*    value={formik.values.description} */}
            {/*    onChange={formik.handleChange} */}
            {/*    error={formik.touched.description && Boolean(formik.errors.description)} */}
            {/*    helperText={formik.touched.description && formik.errors.description} */}
            {/* /> */}
            <ReactQuill
              ref={quillRef}
              style={{ maxHeight: '400px', marginTop: '14px', borderRadius: '4px' }}
              value={body}
              modules={adminModules}
              theme={theme}
              onChange={setBody}
              placeholder="Description*"
            />
            <ReactQuill
              ref={introQuillRef}
              style={{ marginTop: '14px', borderRadius: '4px' }}
              value={bodyIntro}
              modules={introModules}
              theme={theme}
              onChange={setBodyIntro}
              placeholder="Intro*"
            />
            <ReactQuill
              ref={outroQuillRef}
              style={{ marginTop: '14px', borderRadius: '4px' }}
              value={bodyOutro}
              modules={outroModules}
              theme={theme}
              onChange={setBodyOutro}
              placeholder="Outro*"
            />
            <Grid
              container
              spacing={3}
              direction="row"
              justifyContent="space-between"
              alignItems="baseline"
              className="fileDrop"
            >
              <Grid item xs={12} sm={6}>
                <div className="form-group">
                  <label htmlFor="file" className="label">
                    Cover Image*
                  </label>
                  <UploadComponent setFieldValue={formik.setFieldValue} />
                  <Typography>Recommended dimensions: 1200x1200</Typography>
                  {formik.values.file_cover &&
                    formik.values.file_cover.map((file, i) => (
                      <div key={i} style={{ position: 'relative', width: 'fit-content' }}>
                        <Thumb file={file} />
                        <DeleteForeverIcon
                          style={{ cursor: 'pointer', position: 'absolute' }}
                          onClick={() => {
                            formik.setFieldValue('file_cover', []);
                          }}
                        />
                      </div>
                    ))}
                </div>
              </Grid>

              <Grid item xs={12} sm={6}>
                <div className="form-group">
                  <label htmlFor="file" className="label">
                    Header Image*
                  </label>

                  <UploadComponentHeader setFieldValue={formik.setFieldValue} />
                  <Typography>Recommended dimensions: 1200x1200</Typography>
                  {formik.values.file_header &&
                    formik.values.file_header.map((file, i) => (
                      <div key={i} style={{ position: 'relative', width: 'fit-content' }}>
                        <Thumb file={file} />
                        <DeleteForeverIcon
                          style={{ cursor: 'pointer', position: 'absolute' }}
                          onClick={() => {
                            formik.setFieldValue('file_header', []);
                          }}
                        />
                      </div>
                    ))}
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={3} direction="row" justifyContent="space-between" alignItems="center">
              <Grid item xs={12} sm={7}>
                <Grid container spacing={3} direction="row" justifyContent="space-between" alignItems="center">
                  <Grid item xs={12} lg={3}>
                    <div className="colorPicker">
                      <div className="pickerWrap">
                        <input
                          type="color"
                          name="colorMain"
                          label="colorMain"
                          value={formik.values.colorMain}
                          onChange={formik.handleChange}
                        />
                        <input
                          name="colorMain"
                          className="pickerText"
                          onChange={formik.handleChange}
                          value={formik.values.colorMain}
                        />
                      </div>
                      <span>Main color</span>
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={3}>
                    <div className="colorPicker">
                      <div className="pickerWrap">
                        <input
                          type="color"
                          name="colorLightShade"
                          label="colorLightShade"
                          value={formik.values.colorLightShade}
                          onChange={formik.handleChange}
                        />
                        <input
                          name="colorLightShade"
                          className="pickerText"
                          onChange={formik.handleChange}
                          value={formik.values.colorLightShade}
                        />
                      </div>
                      <span>Light shade</span>
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={3}>
                    <div className="colorPicker">
                      <div className="pickerWrap">
                        <input
                          type="color"
                          name="colorDarkShade"
                          label="colorDarkShade"
                          value={formik.values.colorDarkShade}
                          onChange={formik.handleChange}
                        />
                        <input
                          name="colorDarkShade"
                          className="pickerText"
                          onChange={formik.handleChange}
                          value={formik.values.colorDarkShade}
                        />
                      </div>
                      <span>Dark shade</span>
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={3}>
                    <div className="colorPicker">
                      <div className="pickerWrap">
                        <input
                          type="color"
                          name="colorBackground"
                          label="colorBackground"
                          value={formik.values.colorBackground}
                          onChange={formik.handleChange}
                        />
                        <input
                          name="colorBackground"
                          className="pickerText"
                          onChange={formik.handleChange}
                          value={formik.values.colorBackground}
                        />
                      </div>
                      <span>Background color</span>
                    </div>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} lg={12} align="right">
                <Button
                  className="buttonLink"
                  sx={{ mr: 3 }}
                  onClick={() => {
                    history('/workbook');
                  }}
                >
                  Back
                </Button>
                <Button className="buttonLink outline" sx={{ mr: 3 }}>
                  Preview
                </Button>
                <Button className="buttonLink" type="submit">
                  Save and Continue
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormikProvider>
      </div>
    </Paper>
  );
};

export default CreateWorkbook;
