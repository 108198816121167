import React, { createContext, useEffect, useMemo, useReducer, useState } from 'react';
import useUser from '../../Hooks/useUser';
import useNotificationStore from '../../Hooks/useNotification';
import useAlertStore from '../../Hooks/useAlertStore';
import useStore from '../../Hooks/useStore';

const updateLocalStorage = (cart, userId) => {
  localStorage.setItem(`cart-${userId}`, JSON.stringify(cart));
};

export const UserCartContext = createContext({
  cart: [],
  addToCart: () => {},
  changeQuantity: () => {},
  removeFromCart: () => {},
  tax: 0,
  total: 0,
  subtotal: 0,
  isLoading: false,
  handleReset: () => {},
  discount: 0,
  totalItems: 0,
  selectedShippingRate: null,
  setSelectedShippingRate: () => {},
  isAllDigitalProduct: false,
  shipment: null,
  setShipment: () => {},
  relatedProducts: []
});

const cartReducer = (state, action, userId) => {
  switch (action.type) {
    case 'ADD_ITEM': {
      const id = state.findIndex((item) => item._id === action.payload._id);
      const item = state[id];
      let updateItems;

      if (item) {
        const updateItem = {
          ...item,
          quantity: item.quantity + action.payload.quantity
        };
        updateItems = [...state];
        updateItems[id] = updateItem;
      } else {
        updateItems = [...state, action.payload];
      }

      updateLocalStorage(updateItems, userId);
      return updateItems;
    }
    case 'UPDATE':
      const updatedState = action.payload;
      return updatedState;
    case 'CHANGE_QUANTITY': {
      const updatedState = state.map((item) => {
        if (item._id === action.payload.id) {
          if (item.type === 'group') {
            return {
              ...item,
              quantity: 1
            };
          } else {
            return { ...item, quantity: action.payload.quantity };
          }
        } else {
          return item;
        }
      });
      updateLocalStorage(updatedState, userId);
      return updatedState;
    }
    case 'REMOVE_ITEM': {
      const updatedState = state.filter((item) => item._id !== action.payload.id);
      updateLocalStorage(updatedState, userId);
      return updatedState;
    }
    case 'RESET_CART':
      updateLocalStorage([], userId);
      return [];
    default:
      return state;
  }
};

const UserCartContextProvider = ({ children }) => {
  const { currentUser } = useUser();
  const [cart, dispatch] = useReducer((state, action) => cartReducer(state, action, currentUser?._id), []);
  const [selectedShippingRate, setSelectedShippingRate] = useState(null);
  const { addNotification } = useNotificationStore();
  const { openAlert } = useAlertStore();
  const { coupons } = useStore();
  const [shipment, setShipment] = useState(null);

  const subtotal = useMemo(() => {
    return cart.reduce((acc, item) => acc + item.price * item.quantity, 0);
  }, [cart]);

  const tax = 0;

  const calculateDiscount = (product, coupons) => {
    const userId = currentUser?._id;
    let totalDiscount = 0;
    for (const coupon of coupons) {
      if (!coupon) {
        continue;
      }

      // Check if coupon has reached the maximum total redemptions
      if (coupon.max_redemptions && coupon.totalRedemptions >= coupon.max_redemptions) {
        continue;
      }

      // Check if user is excluded
      const userExcluded = coupon.excludeUsers?.some((excludedUser) => excludedUser === userId);
      if (userExcluded) {
        continue;
      }

      // Check if product is excluded
      const productExcluded =
        coupon.excludeProducts?.some((excludedProduct) => excludedProduct === product._id) &&
        coupon.applyToTypes?.includes(product.type);
      if (productExcluded) {
        continue;
      }

      // Check if user is not in the apply list
      const userNotInApplyList =
        coupon.applyToUsers?.length > 0 && !coupon.applyToUsers.some((appliedUser) => appliedUser === userId);
      if (userNotInApplyList) {
        continue;
      }

      // Check if product is not in the apply list
      const productNotInApplyList =
        coupon.applyToProducts?.length > 0 &&
        (!coupon.applyToProducts.some((appliedProduct) => appliedProduct === product._id) ||
          !coupon.applyToTypes?.includes(product.type));
      if (productNotInApplyList) {
        continue;
      }

      // Check if the user has reached the maximum redemptions per user
      if (coupon.maxRedemptionsPerUser) {
        const userRedemption = coupon.userRedemptions.find((u) => u.userId === userId) || { count: 0 };
        if (userRedemption.count >= coupon.maxRedemptionsPerUser) {
          continue;
        }
      }

      if (coupon.percent_off > 0) {
        totalDiscount += (coupon.percent_off / 100) * (product.price * product.quantity);
      } else if (coupon.amount_off > 0) {
        totalDiscount += Math.min(coupon.amount_off, product.price * product.quantity);
      }
    }

    return totalDiscount;
  };

  const total = useMemo(() => {
    const discount = cart.map((item) => calculateDiscount(item, coupons)).reduce((total, value) => total + value, 0);
    const discountedSubtotal = subtotal - discount;

    if (selectedShippingRate) {
      return discountedSubtotal + tax + parseFloat(selectedShippingRate?.amount || 0);
    }

    return discountedSubtotal + tax;
  }, [subtotal, tax, selectedShippingRate, coupons]);

  const addToCart = (item) => {
    if (localStorage.getItem(`cart-${currentUser._id}`) === null) {
      openAlert();
    }
    dispatch({ type: 'ADD_ITEM', payload: item });

    addNotification({
      id: Math.random(),
      message: `${item.title} has been added to your cart `,
      severity: 'success'
    });
  };

  const changeQuantity = (id, quantity) => {
    if (quantity < 1) {
      return;
    }
    dispatch({ type: 'CHANGE_QUANTITY', payload: { id, quantity } });
  };

  const removeFromCart = (id) => {
    dispatch({ type: 'REMOVE_ITEM', payload: { id } });
    addNotification({
      id: Math.random(),
      message: 'Item has been remove from your cart',
      severity: 'success'
    });
  };

  const handleReset = () => {
    dispatch({ type: 'RESET_CART' });
  };

  useEffect(() => {
    if (currentUser?._id) {
      if (localStorage.getItem(`cart-${currentUser._id}`) !== null) {
        const fetchedCarts = JSON.parse(localStorage.getItem(`cart-${currentUser._id}`));

        dispatch({ type: 'UPDATE', payload: fetchedCarts });
      }
    }
  }, [currentUser]);

  return (
    <UserCartContext.Provider
      value={{
        cart,
        addToCart,
        changeQuantity,
        removeFromCart,
        tax,
        total,
        subtotal,
        selectedShippingRate,
        setSelectedShippingRate,
        totalItems: cart.reduce((total, item) => total + parseInt(item.quantity, 10), 0),
        handleReset,
        discount: cart.map((item) => calculateDiscount(item, coupons)).reduce((total, value) => total + value, 0),
        calculateDiscount,
        isAllDigitalProduct: cart.every((item) => item?.isDigitalProduct === 'yes' || item.type === 'group'),
        setShipment,
        shipment,
        relatedProducts: cart
          .map((item) => item.relatedProducts)
          .flat()
          .filter((product) => product !== undefined)
      }}
    >
      {children}
    </UserCartContext.Provider>
  );
};

export default UserCartContextProvider;
