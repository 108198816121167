import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';

import { PaymentElement } from '@stripe/react-stripe-js';
import {
  Grid,
  TextField,
  FormControlLabel,
  Radio,
  FormControl,
  FormHelperText,
  Skeleton,
  Checkbox,
  Button
} from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import useAddressBook from '../../../Hooks/useAddressBook';
import Loading from '../../../components/Loading';
import useStore, { addressInit } from '../../../Hooks/useStore';
import styles from './Checkout.module.scss';
import Accordion from '../../../components/Accordion';
import useBackdrop from '../../../Hooks/useBackdrop';
import { postDataAPI } from '../../../Helpers/FetchAPI';
import useUserCart from '../../../Hooks/useUserCart';
import { formatCurrency } from '../../../Helpers/Functions';
import useUser from '../../../Hooks/useUser';

const DigitalProcess = ({
  isDigital,
  isNewCard,
  setIsNewCard,
  handleDefaultPaymentMethod,
  removePaymentMethod,
  paymentMethodsLoading,
  paymentMethodsData,
  saveCard,
  setSaveCard
}) => {
  const [step, setStep] = useState(0);
  const queryClient = useQueryClient();
  const { currentUser } = useUser();
  const { addressBooksData, addressBooksLoading, removeAddress } = useAddressBook(currentUser?._id);
  const { selectedShippingRate, setSelectedShippingRate, shipment, setShipment } = useUserCart();
  const {
    billingAddress,
    shippingAddress,
    verifiedBilling,
    verifiedShipping,
    setBillingAddress,
    setShippingAddress,
    setVerifiedBilling,
    setVerifiedShipping,
    user
  } = useStore();
  const [inputAddress, setInputAddress] = useState(billingAddress);
  const [inputAddress2, setInputAddress2] = useState(shippingAddress);
  const [billingError, setBillingError] = useState(null);
  const [shippingError, setShippingError] = useState(null);
  const [ratesLoading, setRatesLoading] = useState(false);
  const [shippingRates, setShippingRates] = useState([]);
  const { showBackdrop, hideBackdrop } = useBackdrop();
  const inputRef = useRef(null);
  const inputRef2 = useRef(null);

  const defaultPaymentMethodId = paymentMethodsData?.defaultPaymentMethodId || '';
  const paymentMethods = paymentMethodsData?.paymentMethods || [];

  const handleBillingChange = (e) => {
    const { name, value } = e.target;
    setInputAddress((prev) => ({ ...prev, [name]: value }));
  };
  const handleShippingChange = (e) => {
    const { name, value } = e.target;
    setInputAddress2((prev) => ({ ...prev, [name]: value }));
  };

  const selectBillingAddress = (address) => {
    setBillingAddress(address);
    setInputAddress(address);
    setBillingError(null);
  };

  const handleInputNew = (param) => {
    if (param === 'billing') {
      inputRef?.current.focus();
      setBillingAddress(addressInit);
      setVerifiedBilling(false);
      setInputAddress(addressInit);
    }

    if (param === 'shipping') {
      inputRef2?.current.focus();
      setShippingAddress(addressInit);
      setVerifiedShipping(false);
      setInputAddress2(addressInit);
    }
  };

  const selectShippingAddress = (address) => {
    setShippingError(null);
    setShippingAddress(address);
    setInputAddress2(address);
  };

  const handleVerified = (item) => async () => {
    showBackdrop(true);
    if (item === 'billing') {
      if (JSON.stringify(inputAddress) === JSON.stringify(addressInit)) {
        setBillingError('Billing Info are required');
        hideBackdrop(false);
        return;
      }
      setBillingError(null);
      const validateBillingAddress = await postDataAPI('/api/validate-address', {
        name: inputAddress.name,
        address: inputAddress,
        isSaved: true,
        userId: user._id
      })
        .then((res) => res.data)
        .catch((err) => err.response.data);

      if (validateBillingAddress.statusCode === 400) {
        setBillingError('Billing Address Not Found');
        hideBackdrop(false);
        return;
      }

      if (!validateBillingAddress.isValid) {
        setBillingError('Billing Address Not Found');
        hideBackdrop(false);
        return;
      }

      setBillingAddress(validateBillingAddress.address);
      setVerifiedBilling(validateBillingAddress.isValid);
      queryClient.invalidateQueries('address-books');
      setStep(1);
      hideBackdrop(false);
    }

    if (item === 'shipping') {
      if (JSON.stringify(inputAddress2) === JSON.stringify(addressInit)) {
        setShippingError('Shipping Info are required');
        hideBackdrop(false);
        return;
      }
      setShippingError(null);
      const validateShippingAddress = await postDataAPI('/api/validate-address', {
        name: inputAddress2.name,
        address: inputAddress2,
        isSaved: true,
        userId: user._id
      })
        .then((res) => res.data)
        .catch((err) => err.response.data);

      if (validateShippingAddress.statusCode === 400) {
        setShippingError('Shipping Address Not Found');
        hideBackdrop(false);
        return;
      }

      if (!validateShippingAddress.isValid) {
        setShippingError('Shipping Address Not Found');
        hideBackdrop(false);
        return;
      }

      setVerifiedShipping(validateShippingAddress.isValid);
      setShippingAddress(validateShippingAddress.address);
      queryClient.invalidateQueries('address-books');
      setStep(2);
      hideBackdrop(false);
    }
  };

  const loading = useMemo(() => {
    return ![addressBooksLoading, paymentMethodsLoading].every((item) => item === false);
  }, [addressBooksLoading, paymentMethodsLoading]);

  const handleCreateShippingLabel = useCallback(async () => {
    if (!verifiedShipping) return;
    setRatesLoading(true);
    const label = await postDataAPI('/api/create-shipment', {
      shippingAddress
    })
      .then((res) => res.data)
      .catch((err) => err.response.data);

    setShipment(label);
    setRatesLoading(false);
    setShippingRates(label.rates);
  }, [verifiedShipping, shippingAddress]);

  useEffect(() => {
    let isActive = true;

    if (isActive) {
      if (verifiedBilling) {
        setStep(1);
      }

      if (verifiedShipping) {
        setStep(2);
      }

      if (!isDigital && verifiedBilling && verifiedShipping) {
        handleCreateShippingLabel();
      } else {
        setShipment(null);
        setRatesLoading(false);
        setShippingRates([]);
        setSelectedShippingRate(null);
      }
    }

    return () => {
      isActive = false;
    };
  }, [verifiedBilling, verifiedShipping, isDigital]);

  const checker = isDigital ? 2 : 3;

  if (loading) {
    return <Loading />;
  }

  const accordionItems = [
    {
      title: <span className={styles.checkout_title}>Billing Info {verifiedBilling && <CheckCircleIcon />}</span>,
      content: (
        <div className={styles.checkout_grid}>
          <FormControl
            error={!!billingError}
            sx={{
              gap: '1rem'
            }}
          >
            {addressBooksData.length > 0 &&
              addressBooksData.map((item) => (
                <div key={item.id} className={styles.row_card}>
                  <FormControlLabel
                    value={item.id}
                    checked={item.id === billingAddress.id}
                    control={<Radio />}
                    label={
                      <div className={styles.radio_card}>
                        <span>
                          {item.address}, {item.city}, {item.state}, {item.zip}, {item.country}
                        </span>
                      </div>
                    }
                    sx={{
                      width: '100%'
                    }}
                    onChange={() => selectBillingAddress(item)}
                  />
                  <span
                    className={styles.delete_card}
                    title="Remove Address"
                    onClick={() =>
                      removeAddress(item.id, {
                        onSuccess: () => {
                          if (billingAddress?.id === item.id) {
                            setBillingAddress(addressInit);
                            setVerifiedBilling(false);
                          }

                          if (shippingAddress?.id === item.id) {
                            setShippingAddress(addressInit);
                            setVerifiedShipping(false);
                          }
                        }
                      })
                    }
                  >
                    <DeleteForeverIcon fontSize="inherit" />
                  </span>
                </div>
              ))}

            <TextField
              fullWidth
              placeholder="Name"
              name="name"
              value={inputAddress.name}
              onChange={handleBillingChange}
              inputRef={inputRef}
              error={!!billingError}
            />
            <TextField
              fullWidth
              placeholder="Country"
              name="country"
              value={inputAddress.country}
              onChange={handleBillingChange}
              error={!!billingError}
            />
            <TextField
              fullWidth
              placeholder="Address"
              name="address"
              value={inputAddress.address}
              onChange={handleBillingChange}
              error={!!billingError}
            />
            <TextField
              fullWidth
              placeholder="Address 2"
              name="address2"
              value={inputAddress.address2}
              onChange={handleBillingChange}
              error={!!billingError}
            />
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <TextField
                  fullWidth
                  placeholder="City"
                  value={inputAddress.city}
                  name="city"
                  onChange={handleBillingChange}
                  error={!!billingError}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  placeholder="State"
                  value={inputAddress.state}
                  name="state"
                  onChange={handleBillingChange}
                  error={!!billingError}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  placeholder="Zip"
                  value={inputAddress.zip}
                  name="zip"
                  onChange={handleBillingChange}
                  error={!!billingError}
                />
              </Grid>
            </Grid>
            <FormHelperText
              sx={{
                fontSize: '1.25rem'
              }}
            >
              {billingError}
            </FormHelperText>
            <div className={styles.buttons}>
              <Button
                variant="contained"
                color={verifiedBilling ? 'secondary' : 'primary'}
                disabled={verifiedBilling}
                onClick={handleVerified('billing')}
              >
                {verifiedBilling ? 'Verified' : 'Verify and Save Address'}
              </Button>
              {verifiedBilling && (
                <Button type="button" variant="outlined" color="secondary" onClick={() => handleInputNew('billing')}>
                  Use New Address
                </Button>
              )}
            </div>
          </FormControl>
        </div>
      )
    },
    {
      title: <span className={styles.checkout_title}>Shipping Info {verifiedShipping && <CheckCircleIcon />}</span>,
      content: (
        <div className={styles.checkout_grid}>
          <FormControl
            error={!!shippingError}
            sx={{
              gap: '1rem'
            }}
          >
            {addressBooksData.length > 0 &&
              addressBooksData.map((item) => (
                <div key={item.id} className={styles.row_card}>
                  <FormControlLabel
                    value={item.id}
                    checked={item.id === shippingAddress.id}
                    control={<Radio />}
                    label={
                      <div className={styles.radio_card}>
                        <span>
                          {item.address}, {item.city}, {item.state}, {item.zip}, {item.country}
                        </span>
                      </div>
                    }
                    sx={{
                      width: '100%'
                    }}
                    onChange={() => selectShippingAddress(item)}
                  />
                  <span
                    className={styles.delete_card}
                    title="Remove Address"
                    onClick={() =>
                      removeAddress(item.id, {
                        onSuccess: () => {
                          if (billingAddress?.id === item.id) {
                            setBillingAddress(addressInit);
                            setVerifiedBilling(false);
                          }

                          if (shippingAddress?.id === item.id) {
                            setShippingAddress(addressInit);
                            setVerifiedShipping(false);
                          }
                        }
                      })
                    }
                  >
                    <DeleteForeverIcon fontSize="inherit" />
                  </span>
                </div>
              ))}
            {/* <AddressVerification onAddressSelect={handleShippingAddress} selectedAddress={shippingAddress} /> */}

            <TextField
              fullWidth
              placeholder="Name"
              value={inputAddress2.name}
              name="name"
              onChange={handleShippingChange}
              inputRef={inputRef2}
              error={!!shippingError}
            />

            <TextField
              fullWidth
              placeholder="Country"
              value={inputAddress2.country}
              name="country"
              onChange={handleShippingChange}
              error={!!shippingError}
            />
            <TextField
              fullWidth
              placeholder="Address"
              value={inputAddress2.address}
              name="address"
              onChange={handleShippingChange}
              error={!!shippingError}
            />
            <TextField
              fullWidth
              placeholder="Address 2"
              value={inputAddress2.address2}
              name="address2"
              onChange={handleShippingChange}
              error={!!shippingError}
            />
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <TextField
                  fullWidth
                  placeholder="City"
                  name="city"
                  value={inputAddress2.city}
                  onChange={handleShippingChange}
                  error={!!shippingError}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  placeholder="State"
                  value={inputAddress2.state}
                  name="state"
                  onChange={handleShippingChange}
                  error={!!shippingError}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  placeholder="Zip"
                  value={inputAddress2.zip}
                  name="postal_code"
                  onChange={handleShippingChange}
                  error={!!shippingError}
                />
              </Grid>
            </Grid>
            <FormHelperText
              sx={{
                fontSize: '1.25rem'
              }}
            >
              {shippingError}
            </FormHelperText>
            <div className={styles.buttons}>
              <Button
                variant="contained"
                color={verifiedShipping ? 'secondary' : 'primary'}
                disabled={verifiedShipping}
                onClick={handleVerified('shipping')}
              >
                {verifiedShipping ? 'Verified' : 'Verify and Save Address'}
              </Button>
              {verifiedShipping && (
                <Button variant="outlined" color="secondary" onClick={() => handleInputNew('shipping')}>
                  Use New Address
                </Button>
              )}
            </div>
          </FormControl>
        </div>
      )
    },
    {
      title: (
        <span className={styles.checkout_title}>Shipping Rates {selectedShippingRate && <CheckCircleIcon />}</span>
      ),
      content: (
        <div className={styles.checkout_grid}>
          {ratesLoading && (
            <>
              <Skeleton variant="rectangular" height={60} />
              <Skeleton variant="rectangular" height={60} />
              <Skeleton variant="rectangular" height={60} />
              <Skeleton variant="rectangular" height={60} />
            </>
          )}
          {shippingRates.length > 0 &&
            !ratesLoading &&
            shippingRates.map((item) => (
              <div key={item.object_id} className={styles.checkout_shipping}>
                <div className={styles.col}>
                  <div className={styles.checkout_provider}>
                    <img src={item.provider_image_75} alt={item.provider} />
                    <span className={styles.checkout_label}>
                      {item.provider}-{item.servicelevel.name}
                    </span>
                  </div>
                  <span className={styles.duration}>{item.duration_terms}</span>
                </div>
                <div className={styles.checkout_provider}>
                  <span className={styles.checkout_currency}>
                    {formatCurrency(item?.amount)} {item?.currency}
                  </span>
                  <Button
                    onClick={() => {
                      setSelectedShippingRate(item);
                      setStep(3);
                    }}
                  >
                    {selectedShippingRate?.object_id === item.object_id ? 'Applied' : 'Apply'}
                  </Button>
                </div>
              </div>
            ))}
        </div>
      )
    },
    {
      title: (
        <span className={styles.checkout_title}>
          Payment Method {step === checker && (isNewCard || defaultPaymentMethodId) && <CheckCircleIcon />}
        </span>
      ),
      content: (
        <div className={styles.checkout_grid}>
          <form className={styles.form}>
            {paymentMethods.length > 0 &&
              paymentMethods.map((item) => (
                <div key={item.id} className={styles.row_card}>
                  <FormControlLabel
                    value={item.id}
                    checked={!isNewCard && item.id === defaultPaymentMethodId}
                    control={<Radio />}
                    label={
                      <div className={styles.radio_card}>
                        <span className={styles.numbers}>**** **** **** {item.card.last4}</span>
                        <span className={styles.row}>
                          <span>
                            {item.card.exp_month}/{item.card.exp_year}
                          </span>
                          <span>{item.card.brand}</span>
                        </span>
                      </div>
                    }
                    sx={{
                      width: '100%'
                    }}
                    onChange={handleDefaultPaymentMethod(item.id)}
                  />
                  <span
                    className={styles.delete_card}
                    title="Remove Payment Method"
                    onClick={removePaymentMethod(item.id)}
                  >
                    <DeleteForeverIcon fontSize="inherit" />
                  </span>
                </div>
              ))}
            {paymentMethods.length > 0 && (
              <FormControlLabel
                value={isNewCard}
                checked={isNewCard || defaultPaymentMethodId === ''}
                control={<Radio />}
                label={
                  <div className={styles.radio_card}>
                    <span>Use New Payment Method</span>
                  </div>
                }
                sx={{
                  backgroundColor: '#90909030',
                  width: '100%',
                  padding: '1rem',
                  margin: '.25rem 1rem'
                }}
                onChange={() => {
                  setIsNewCard((prev) => !prev);
                }}
              />
            )}
            {(isNewCard || defaultPaymentMethodId === '') && (
              <div className={styles.col}>
                <div style={{ marginBottom: '2rem' }}>
                  <FormControlLabel
                    control={<Checkbox />}
                    checked={saveCard}
                    onChange={() => setSaveCard((prev) => !prev)}
                    label={
                      <span
                        style={{
                          fontWeight: 500
                        }}
                      >
                        Save card for future payments
                      </span>
                    }
                  />
                </div>
                <PaymentElement />
              </div>
            )}
          </form>
        </div>
      )
    }
  ];

  if (isDigital) {
    accordionItems.splice(2, 1);
  }

  return <Accordion items={accordionItems} controlled={step} />;
};

export default DigitalProcess;
