/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
import React, { useState } from 'react';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider
} from 'react-router-dom';
import PageLayout, { layoutLoader } from '../PageLayout';
import PageRender from '../PageRender';
import Dashboard from '../../Pages/Dashboard';
import WorkbookList from '../../views/Workbook/workbook-list.component';
import CreateWorkbook from '../../views/Workbook/create-workbook.component';
import EditWorkbook from '../../views/Workbook/edit-workbook.component';
import Checkout, { checkoutLoader } from '../../Pages/User/checkout';
import useAuth from '../../Hooks/useAuth';
import Loading from '../Loading';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import ErrorPage from '../../Pages/Error';

const publicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

const stripePromise = loadStripe(publicKey);

const ProtectedRoutes = () => {
  const { isLoading, userData } = useAuth();

  const [opensec, setOpensec] = useState(localStorage.getItem('openCateogry'));

  const options = {
    mode: 'setup',
    currency: 'usd',
    paymentMethodCreation: 'manual'
  };

  if (isLoading) {
    return <Loading />;
  }

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<PageLayout />} loader={layoutLoader} errorElement={<ErrorPage />} id="root">
        <Route index element={<Dashboard />} id="dashboard" />
        <Route path="/:page" element={<PageRender />} id="pageRender" />
        <Route path="/:page/:id" element={<PageRender />} id="pageRenderWithId" />
        <Route path="/:page/:id/view" element={<PageRender />} id="pageRenderView" />
        {userData?.role === 'admin' && (
          <>
            <Route path="workbook" element={<WorkbookList />} id="workbookList" />
            <Route path="workbook/create-workbook" element={<CreateWorkbook />} id="createWorkbook" />
            <Route path="workbook/workbook-list" element={<WorkbookList />} id="workbookListAgain" />
            <Route
              path="workbook/:id"
              element={<EditWorkbook activeCat={opensec} setActiveCat={setOpensec} />}
              id="editWorkbook"
            />
          </>
        )}
        <Route path="/cart/checkout" element={<Checkout />} id="checkout" />

        <Route path="*" element={<Navigate to="/" />} id="navigateRoot" />
        <Route path="/signup" element={<Navigate to="/" />} id="navigateSignup" />
      </Route>
    )
  );

  return (
    <Elements stripe={stripePromise} options={options}>
      <RouterProvider router={router} />
    </Elements>
  );
};

export default ProtectedRoutes;
