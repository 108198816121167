import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

const Title = ({ children, onClick }) => {
  return (
    <Typography
      component="h2"
      variant="h2"
      gutterBottom
      textAlign="left"
      sx={{
        fontSize: '4.8rem',
        fontWeight: '400',
        color: '#00998c',
        lineHeight: '4.1rem',
        marginBottom: '1.5rem',
        fontFamily: 'Bebas Neue',
        cursor: onClick ? 'pointer' : 'default'
      }}
      onClick={onClick}
    >
      {children}
    </Typography>
  );
};

Title.propTypes = {
  children: PropTypes.node
};

export default Title;
