import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { getDataAPI, postDataAPI } from '../../Helpers/FetchAPI';
import useUser from '../../Hooks/useUser';

const initState = {
  title: '',
  description: '',
  requiredScore: 0,
  questions: []
};

export const UserAssessmentsContext = createContext({
  initState,
  assessmentsData: [],
  setAssessmentsData: () => {},
  assessment: initState,
  setAssessment: () => {},
  isLoading: false,
  answers: [],
  setAnswers: () => {},
  error: null,
  getTakenAssessment: () => {},
  setTrigger: () => {}
});

const UserAssessmentsContextProvider = ({ children }) => {
  const { currentUser } = useUser();
  const [assessmentsData, setAssessmentsData] = useState([]);
  const [assessment, setAssessment] = useState(initState);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [answers, setAnswers] = useState([]);
  const [trigger, setTrigger] = useState(true);

  const getPublishAssessments = useCallback(async () => {
    await getDataAPI('/api/assessments/publish')
      .then((res) => {
        const { success, data } = res.data;

        if (success) {
          setAssessmentsData(data);
          setIsLoading(false);
          setTrigger(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err);
        setTrigger(false);
      });
  }, []);

  const getTakenAssessment = useCallback(async (id, callback) => {
    await getDataAPI(`/api/assessment/take/${id}`).then((res) => {
      const { success, data } = res.data;

      if (success) {
        callback(data);
      }
    });
  }, []);

  useEffect(() => {
    let isActive = true;

    if (isActive) {
      if (currentUser && trigger) {
        setIsLoading(false);
        getPublishAssessments();
      }
    }

    return () => {
      isActive = false;
    };
  }, [currentUser, getPublishAssessments, trigger]);

  const value = useMemo(() => {
    return {
      initState,
      assessmentsData,
      setAssessmentsData,
      assessment,
      setAssessment,
      isLoading,
      error,
      answers,
      setAnswers,
      getTakenAssessment,
      setTrigger
    };
  }, [answers, assessment, assessmentsData, error, getTakenAssessment, isLoading]);
  return <UserAssessmentsContext.Provider value={value}>{children}</UserAssessmentsContext.Provider>;
};

export default UserAssessmentsContextProvider;
