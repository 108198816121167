import React from 'react';
import ReactDOM from 'react-dom/client';
import { Amplify } from 'aws-amplify';
import { QueryClient, QueryClientProvider } from 'react-query';
import './Styles/main.scss';
import App from './App';
import './i18nextInit';
import * as serviceWorker from './serviceWorker';
import cognitoConfig from './config/cognitoConfig.json';
import { ReactQueryDevtools } from 'react-query/devtools';
import { configureAxios } from './utils/configureAxios';
import { queryClient } from './lib/store';

configureAxios();

const root = ReactDOM.createRoot(document.getElementById('root'));

Amplify.configure({
  Auth: {
    userPoolId: cognitoConfig.userPool,
    userPoolWebClientId: cognitoConfig.clientId,
    region: cognitoConfig.region,
    oauth: {
      domain: cognitoConfig.userPoolUri,
      scope: cognitoConfig.tokenScopes,
      redirectSignIn: cognitoConfig.callbackUri,
      redirectSignOut: cognitoConfig.signoutUri,
      responseType: 'code'
    }
    // storage: CustomChromeStorage
  }
});

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>
);

// ReactDOM.createRoot(
//     <>
//         <App />
//     </>,
//     document.getElementById('root')
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
