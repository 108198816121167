import React from 'react';
import { Navigate, Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ForgotPassword from './ForgotPassword';
import SignUp from './SignUp';
import SignIn from './SignIn';
import signInBg from '../../images/login-bg.jpg';
import SetPassword from './SetPassword';
import logo from '../../images/pure-desire-logo-2022.png';
import './SignIn.css';
import Button from '../../components/Button';

const Copyright = (sx) => {
  return (
    <Typography
      component="div"
      variant="div"
      color="primary.neutral"
      align="left"
      style={{
        marginTop: 'auto',
        fontSize: '15px',
        color: '#555555',
        position: 'relative',
        width: '100%'
      }}
      sx={sx}
    >
      <div className="copyright">
        {'Copyright © '} {new Date().getFullYear()} Pure Desire Ministries
        <span className="version-number">Version {process.env.REACT_APP_PD_VERSION}</span>
      </div>
    </Typography>
  );
};

const theme = createTheme();

const Authentication = () => {
  return (
    <ThemeProvider theme={theme}>
      <Button
        onClick={() => {
          window.location.assign('/');
        }}
        variant="primary"
        className="logo-top"
      >
        <img src={logo} alt="Pure Desire Ministries" />
      </Button>

      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={12}
          sm={8}
          md={6}
          component={Paper}
          elevation={6}
          square
          sx={{ margin: 'auto', boxShadow: 'none' }}
        >
          <Router>
            <Routes>
              <Route path="/" element={<SignIn />} />
              <Route path="signup" element={<SignUp />} />
              <Route path="forgot-password" element={<ForgotPassword />} />
              <Route path="set-password" element={<SetPassword />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </Router>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Grid>
        <Grid
          item
          xs={false}
          sm={4}
          md={6}
          sx={{
            backgroundImage: `url(${signInBg})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
          className="loginBg"
        />
      </Grid>
    </ThemeProvider>
  );
};

export default Authentication;
