import { Box, Button, Divider, Grid, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import AssessmentContent2 from './CreateAssessment/AssessmentContent2';
import AssessmentContent1 from './CreateAssessment/AssessmentContent1';
import AssessmentContent3 from './CreateAssessment/AssessmentContent3';
import Modal from '../../Modal';

function endsWithNumber(str) {
  return /[0-9]+$/.test(str);
}

// ✅ Get number at end of string ✅
function getNumberAtEnd(str) {
  if (endsWithNumber(str)) {
    return Number(str.match(/[0-9]+$/)[0]);
  }

  return null;
}

const EditAssessment = ({ snack, setSnack, item, close, titleInfo }) => {
  const queryClient = useQueryClient();
  const [elements, setElements] = useState(() => {
    const eleArr = [];
    item.questions.map((e, i) => {
      const key = Date.now() + i;
      if (e.type === 'checkbox' || e.type === 'radio') {
        const optArr = [];
        e.options.map((option, j) => {
          const oKey = Date.now() + i + j;
          optArr.push({ value: option.title, key: oKey, points: option.points });
        });
        eleArr.push({
          type: e.type,
          options: optArr,
          key,
          _id: e._id
        });
      } else if (e.type === 'content') {
        eleArr.push({
          type: e.type,
          options: [e.title],
          key,
          _id: e._id
        });
      } else if (e.type === 'rating') {
        eleArr.push({
          type: e.type,
          descHigh: e.descHigh,
          descLow: e.descLow,
          scaleDesc: e.scaleDesc,
          options: [],
          key,
          _id: e._id
        });
      } else {
        eleArr.push({
          type: e.type,
          options: [],
          key,
          _id: e._id
        });
      }
    });
    return eleArr;
  });

  const [initialValues, setInitialValues] = useState(() => {
    const initValues = {};
    initValues.assessmentTitle = item.title;
    initValues.requiredScore = item.required_score;
    initValues.type = item.type;
    item.questions.map((e, index) => {
      const element = elements[index];
      initValues[`${e.type}${element.key}`] = e.title;
      if (e.type === 'checkbox' || e.type === 'radio') {
        e.options.map((option, j) => {
          console.log(element);
          console.log(`${element.key}options${element.options[j].key}`);

          initValues[`${element.key}options${element.options[j].key}`] = option.title;
          initValues[`${element.key}points${element.options[j].key}`] = parseInt(option.points);
        });
      } else if (e.type === 'rating') {
        initValues[`highScale${element.key}`] = e.descHigh;
        initValues[`lowScale${element.key}`] = e.descLow;
        initValues[`scaleDesc${element.key}`] = e.scaleDesc;
      } else {
        initValues[`points${element.key}`] = parseInt(e.points);
      }
    });

    return initValues;
  });
  const [errorContents, setErrorContents] = useState([]);

  const updateWorkBookItemMutation = useMutation(
    (params) => {
      return axios.put(`${process.env.REACT_APP_API_URL}/workbookItem/update-workbookitem/${item._id}`, params);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('sectionItems');
        queryClient.invalidateQueries('bookSection');
        close();

        setSnack(true);
      },
      onError: (err) => {
        alert(err.message);
      }
    }
  );

  const saveWorkBookItemMutation = useMutation(
    (params) => {
      return axios.put(`${process.env.REACT_APP_API_URL}/workbookItem/update-workbookitem/${item._id}`, params);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('sectionItems');
        queryClient.invalidateQueries('bookSection');
        setSnack(true);
      },
      onError: (err) => {
        alert(err.message);
      }
    }
  );

  function addItem(type) {
    const key = Date.now();

    switch (type) {
      case 'text':
        setElements([...elements, { type: 'text', options: [], key }]);
        break;
      case 'textarea':
        setElements([...elements, { type: 'textarea', options: [], key }]);
        break;
      case 'rating':
        setElements([...elements, { type: 'rating', options: [], key }]);
        break;
      case 'checkbox':
        setElements([...elements, { type: 'checkbox', options: [], key }]);
        break;
      case 'radio':
        setElements([...elements, { type: 'radio', options: [], key }]);
        break;
      case 'content':
        setElements([...elements, { type: 'content', options: [], key }]);
        break;
    }
  }

  const handleRemove = (index, element) => {
    setElements((elements) => elements.filter((e, i) => i !== index));
    const newObjValues = new Object();
    let index2;

    Object.keys(formik.values).forEach((key) => {
      if (endsWithNumber(key)) {
        if (getNumberAtEnd(key) > index) {
          const arr = key.split('');
          arr[arr.length - 1] = parseInt(arr[arr.length - 1]);
          index2 = arr[arr.length - 1];
          arr[arr.length - 1] = arr[arr.length - 1] - 1;
          newObjValues[arr.join('')] = formik.values[key];
        } else if (getNumberAtEnd(key) < index) {
          newObjValues[key] = formik.values[key];
        } else {
          console.log('removed');
        }
      } else {
        newObjValues[key] = formik.values[key];
      }
    });

    formik.values = { ...newObjValues };
  };

  const handleRemoveSubItem = (i, j) => {
    setElements((elements) =>
      elements.map((e, index) => {
        if (index === i) {
          const newoptions = e.options.filter((item, order) => order !== j);
          e.options = newoptions;
          return e;
        }
        return e;
      })
    );
  };

  const handleAddSubItem = (i) => {
    setElements((elements) =>
      elements.map((e, index) => {
        const key = Date.now();
        if (index === i) {
          e.options.push({ value: 'item', key });
          return e;
        }
        return e;
      })
    );
  };

  const addContent = (cont, i) => {
    setElements((elements) =>
      elements.map((e, index) => {
        if (index === i) {
          e.options[0] = cont;
          return e;
        }
        return e;
      })
    );
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: '',

    setFieldValue: (field) => {
      delete formik.values[field];
    },

    onSubmit: (fields, { close }) => {
      const checkContent = elements.some((el) => el.type === 'content');
      if (checkContent) {
        const filteredElement = elements.filter((el) => el.type === 'content');
        if (filteredElement.some((el) => el.options.length === 0)) {
          setErrorContents(filteredElement.filter((el) => el.options.length === 0));
          return;
        }
        setErrorContents([]);
      }

      const quesArray = [];
      elements.map((item, index) => {
        if (item.type === 'checkbox' || item.type === 'radio') {
          const optionArr = [];
          item.options.map((option, j) => {
            console.log(`${item.key}options${option.key}`);
            console.log(fields[`${item.key}options${option.key}`]);
            optionArr.push({
              title: fields[`${item.key}options${option.key}`],
              points: fields[`${item.key}points${option.key}`]
            });
          });

          quesArray.push({
            title: fields[`${item.type}${item.key}`],
            type: item.type,
            options: optionArr,
            _id: item._id
          });
        } else if (item.type === 'content') {
          quesArray.push({
            title: item.options[0] ? item.options[0] : '',
            type: item.type,
            _id: item._id
          });
        } else if (item.type === 'rating') {
          quesArray.push({
            title: fields[`${item.type}${item.key}`],
            type: item.type,
            points: fields[`points${item.key}`] ? parseInt(fields[`points${item.key}`]) : 1,
            scaleDesc: fields[`scaleDesc${item.key}`] ? fields[`scaleDesc${item.key}`] : '',
            descHigh: fields[`highScale${item.key}`] ? fields[`highScale${item.key}`] : '',
            descLow: fields[`lowScale${item.key}`] ? fields[`lowScale${item.key}`] : '',
            _id: item._id
          });
        } else {
          quesArray.push({
            title: fields[`${item.type}${item.key}`],
            type: item.type,
            points: fields[`points${item.key}`] ? parseInt(fields[`points${item.key}`]) : 1,
            _id: item._id
          });
        }
      });

      const params = {
        title: fields.assessmentTitle,
        type: fields.type,
        required_score: fields.requiredScore,
        questions: quesArray
      };

      updateWorkBookItemMutation.mutate(params);
    }
  });

  const handleSaving = () => {
    const fields = formik.values;
    // Check content validation
    const checkContent = elements.some((el) => el.type === 'content');
    if (checkContent) {
      const filteredElement = elements.filter((el) => el.type === 'content');
      if (filteredElement.some((el) => el.options.length === 0)) {
        setErrorContents(filteredElement.filter((el) => el.options.length === 0));
        return;
      }
      setErrorContents([]);
    }

    const quesArray = [];
    elements.map((item, index) => {
      if (item.type === 'checkbox' || item.type === 'radio') {
        const optionArr = [];
        item.options.map((option, j) => {
          console.log(`${item.key}options${option.key}`);
          console.log(fields[`${item.key}options${option.key}`]);
          optionArr.push({
            title: fields[`${item.key}options${option.key}`],
            points: fields[`${item.key}points${option.key}`]
          });
        });

        quesArray.push({
          title: fields[`${item.type}${item.key}`],
          type: item.type,
          options: optionArr,
          _id: item._id
        });
      } else if (item.type === 'content') {
        quesArray.push({
          title: item.options[0] ? item.options[0] : '',
          type: item.type,
          _id: item._id
        });
      } else if (item.type === 'rating') {
        quesArray.push({
          title: fields[`${item.type}${item.key}`],
          type: item.type,
          points: fields[`points${item.key}`] ? parseInt(fields[`points${item.key}`]) : 1,
          scaleDesc: fields[`scaleDesc${item.key}`] ? fields[`scaleDesc${item.key}`] : '',
          descHigh: fields[`highScale${item.key}`] ? fields[`highScale${item.key}`] : '',
          descLow: fields[`lowScale${item.key}`] ? fields[`lowScale${item.key}`] : '',
          _id: item._id
        });
      } else {
        quesArray.push({
          title: fields[`${item.type}${item.key}`],
          type: item.type,
          points: fields[`points${item.key}`] ? parseInt(fields[`points${item.key}`]) : 1,
          _id: item._id
        });
      }
    });

    const params = {
      title: fields.assessmentTitle,
      type: fields.type,
      required_score: fields.requiredScore,
      questions: quesArray
    };

    saveWorkBookItemMutation.mutate(params);
  };

  const handleDragandDrop = (result) => {
    if (!result.destination) return;

    setElements((elements) => {
      const [removed] = elements.splice(result.source.index, 1);
      elements.splice(result.destination.index, 0, removed);
      return elements;
    });
  };

  console.log({ elements });

  return (
    <Modal variant="large">
      <div>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            {/* add this functionality to other edit and creations modals */}
            <Box className="modal-wrap" style={{ display: 'flex', flexDirection: 'column' }}>
              <Box className="modal-header" style={{ maxHeight: 'inherit', padding: '0px' }}>
                <Typography variant="h2"> {titleInfo.title} </Typography>
                <Typography variant="body1"> {titleInfo.description} </Typography>
                <Divider style={{ margin: '20px 0' }} />
              </Box>
              <Box
                className="modal-body"
                style={{
                  overflowY: 'auto',
                  maxHeight: '675px',
                  overflowX: 'auto',
                  padding: '0px',
                  width: '100%'
                  // height: '75%'
                }}
              >
                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justifyContent="space-between"
                  style={{ marginTop: '0' }}
                  alignItems="center"
                >
                  <Grid item xs={12} sm={8} align="left" style={{ display: 'flex', flexDirection: 'column' }}>
                    <label>Title</label>
                    <TextField
                      margin="normal"
                      required
                      id="assessmentTitle"
                      name="assessmentTitle"
                      placeholder="Type here..."
                      value={formik.values.assessmentTitle}
                      onChange={formik.handleChange}
                      error={formik.touched.assessmentTitle && Boolean(formik.errors.assessmentTitle)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} align="left" style={{ display: 'flex', flexDirection: 'column' }}>
                    <label>Minimum score needed to pass</label>
                    <TextField
                      type="number"
                      margin="normal"
                      required
                      id="requiredScore"
                      name="requiredScore"
                      // label="Section Title"
                      // placeholder="1"
                      value={formik.values.requiredScore}
                      onChange={formik.handleChange}
                      error={formik.touched.requiredScore && Boolean(formik.errors.requiredScore)}
                    />
                  </Grid>
                </Grid>
                <Divider style={{ margin: '20px 0' }} />
                <Typography variant="h2">Tool</Typography>
                <DragDropContext onDragEnd={handleDragandDrop}>
                  <Droppable droppableId="droppable">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {elements.map((e, i) => (
                          <Draggable key={`item-${e.key}`} draggableId={`item-${e.key}`} index={i}>
                            {(provided) => (
                              <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                {e.type === 'text' || e.type === 'textarea' || e.type === 'rating' ? (
                                  <AssessmentContent1
                                    key={e.key}
                                    item={e}
                                    index={i}
                                    removeItem={() => handleRemove(i)}
                                    formik={formik}
                                  />
                                ) : e.type === 'content' ? (
                                  <AssessmentContent3
                                    key={e.key}
                                    item={e}
                                    addContent={(e) => addContent(e, i)}
                                    removeItem={() => handleRemove(i)}
                                    errorContents={errorContents}
                                  />
                                ) : (
                                  <AssessmentContent2
                                    key={e.key}
                                    item={e}
                                    index={i}
                                    formik={formik}
                                    removeItem={() => handleRemove(i)}
                                    addSubItem={() => handleAddSubItem(i)}
                                    removeSubitem={(j) => handleRemoveSubItem(i, j)}
                                  />
                                )}
                              </div>
                            )}
                          </Draggable>
                        ))}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                <Box
                  className="dashed-border"
                  sx={{
                    minHeight: '180px',
                    borderRadius: '4px',
                    margin: '20px 0',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '30px'
                  }}
                >
                  <Grid container spacing={10} direction="row" justifyContent="center" alignItems="center">
                    <Grid item>
                      <div className="o_icon_wrapper" onClick={() => addItem('content')}>
                        <AddIcon style={{ fill: '#FFA659' }} />
                      </div>
                      <Typography style={{ color: '#00000099', fontSize: '15px', paddingTop: '5px' }}>
                        Add Content
                      </Typography>
                    </Grid>
                    <Grid item>
                      <div className="o_icon_wrapper" onClick={() => addItem('text')}>
                        <AddIcon style={{ fill: '#FFA659' }} />
                      </div>
                      <Typography style={{ color: '#00000099', fontSize: '15px', paddingTop: '5px' }}>
                        Add Text Input
                      </Typography>
                    </Grid>
                    <Grid item>
                      <div className="o_icon_wrapper" onClick={() => addItem('textarea')}>
                        <AddIcon style={{ fill: '#FFA659' }} />
                      </div>
                      <Typography style={{ color: '#00000099', fontSize: '15px', paddingTop: '5px' }}>
                        Add Text Area
                      </Typography>
                    </Grid>
                    <Grid item>
                      <div className="o_icon_wrapper" onClick={() => addItem('rating')}>
                        <AddIcon style={{ fill: '#FFA659' }} />
                      </div>
                      <Typography style={{ color: '#00000099', fontSize: '15px', paddingTop: '5px' }}>
                        Rating(1-10)
                      </Typography>
                    </Grid>
                    <Grid item>
                      <div className="o_icon_wrapper" onClick={() => addItem('checkbox')}>
                        <AddIcon style={{ fill: '#FFA659' }} />
                      </div>
                      <Typography style={{ color: '#00000099', fontSize: '15px', paddingTop: '5px' }}>
                        Checkboxes
                      </Typography>
                    </Grid>
                    <Grid item>
                      <div className="o_icon_wrapper" onClick={() => addItem('radio')}>
                        <AddIcon style={{ fill: '#FFA659' }} />
                      </div>
                      <Typography style={{ color: '#00000099', fontSize: '15px', paddingTop: '5px' }}>
                        Radio Buttons
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <div />
              </Box>
              <Box className="modal-footer" style={{ width: '100%', maxHeight: 'inherit', minHeight: '20px' }}>
                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justifyContent="flex-end"
                  style={{ marginTop: '0' }}
                  alignItems="center"
                >
                  <Grid item align="right">
                    <Button className="buttonLink outline red" onClick={close}>
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item align="center">
                    <Button className="buttonLink outline" type="button" onClick={handleSaving}>
                      Save
                    </Button>
                  </Grid>
                  <Grid item align="right">
                    <Button className="buttonLink" type="submit">
                      Save and Exit
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </form>
        </FormikProvider>
      </div>
    </Modal>
  );
};

export default EditAssessment;
