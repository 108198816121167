import React, { useEffect, useState } from 'react';
import RemoveIcon from '@mui/icons-material/Remove';
import { Grid, TextField } from '@mui/material';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export default function AssessmentContent1({ item, index, removeItem, formik }) {
  const [title, setTitle] = useState('');
  const [checked, setChecked] = React.useState(!!item.scaleDesc);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  let isRating;
  if (item.type == 'rating') {
    isRating = true;
  } else {
    isRating = false;
  }

  useEffect(() => {
    switch (item.type) {
      case 'text':
        setTitle('Text Input*');
        break;
      case 'textarea':
        setTitle('Text Area*');
        break;
      case 'rating':
        setTitle('Rating (1-10)*');
        break;
      default:
        break;
    }
  }, [item]);

  return (
    <Grid
      container
      spacing={3}
      direction="row"
      justifyContent="space-between"
      style={{ marginTop: '0' }}
      alignItems="center"
    >
      <Grid item xs={11} sm={11.5}>
        <Grid
          container
          spacing={3}
          direction="row"
          justifyContent="space-between"
          style={{ marginTop: '0' }}
          alignItems="center"
        >
          <Grid
            item
            xs={12}
            sm={10}
            align="left"
            style={{ display: 'flex', flexDirection: 'column', paddingTop: '0px' }}
          >
            <label>{title}</label>

            <TextField
              margin="normal"
              required
              id={`${item.type}${item.key}`}
              value={formik.values[`${item.type}${item.key}`] ? formik.values[`${item.type}${item.key}`] : ''}
              name={`${item.type}${item.key}`}
              placeholder="Type here..."
              onChange={formik.handleChange}
            />

            <small style={{ visibility: isRating ? 'visible' : 'hidden', flexDirection: 'column', marginLeft: '20px' }}>
              *Provide a discriptor for the high and low end of the scale or just create a description
            </small>
          </Grid>
          <Grid
            item
            xs={12}
            sm={2}
            align="left"
            style={{ display: 'flex', flexDirection: 'column', paddingTop: '0px' }}
          >
            <label>Value (for scoring)</label>
            <TextField
              type="number"
              margin="normal"
              required
              id={`points${item.key}`}
              value={formik.values[`points${item.key}`] ? formik.values[`points${item.key}`] : 1}
              name={`points${item.key}`}
              placeholder="1"
              onChange={formik.handleChange}
            />
            <small style={{ visibility: 'hidden' }}>...</small>
          </Grid>
          <Grid
            item
            xs={8}
            sm={8}
            align="center"
            style={{
              display: isRating && checked ? 'flex' : 'none',
              flexDirection: 'column',
              marginLeft: '20px'
            }}
          >
            <label>Description</label>
            <TextField
              margin="normal"
              id={`scaleDesc${item.key}`}
              value={formik.values[`scaleDesc${item.key}`] ? formik.values[`scaleDesc${item.key}`] : ''}
              name={`scaleDesc${item.key}`}
              placeholder=""
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid
            item
            xs={3}
            sm={3}
            align="center"
            style={{ display: isRating && !checked ? 'flex' : 'none', flexDirection: 'column', marginLeft: '20px' }}
          >
            <label>Low</label>
            <TextField
              margin="normal"
              id={`lowScale${item.key}`}
              value={formik.values[`lowScale${item.key}`] ? formik.values[`lowScale${item.key}`] : ''}
              name={`lowScale${item.key}`}
              placeholder=""
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid
            item
            xs={3}
            sm={3}
            align="center"
            style={{ display: isRating && !checked ? 'flex' : 'none', flexDirection: 'column', marginLeft: '20px' }}
          >
            <label>High</label>
            <TextField
              margin="normal"
              id={`highScale${item.key}`}
              value={formik.values[`highScale${item.key}`] ? formik.values[`highScale${item.key}`] : ''}
              name={`highScale${item.key}`}
              placeholder=""
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid
            item
            xs={2}
            sm={0}
            align="center"
            style={{ display: isRating ? 'flex' : 'none', flexDirection: 'column', marginRight: '20px' }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>Scale</Typography>
              <Switch checked={checked} onChange={handleChange} />
              <Typography>Description</Typography>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={1} sm={0.5} align="right">
        <RemoveIcon
          onClick={removeItem}
          style={{ background: '#E85E40', borderRadius: '50%', fill: 'white', marginTop: '30px', cursor: 'pointer' }}
        />
      </Grid>
    </Grid>
  );
}
