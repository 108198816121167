import React, { useState } from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Modal from '../../Modal';
import LayerList from '../../LayerList';
import WorkbookItem from '../../WorkbookItem';
import Button from '../../Button';
import RenderString from '../../RenderString';

import styles from './previewWorkbook.module.scss';
import Title from '../../Title';

const PreviewWorkbooks = ({ setPreview, workbook }) => {
  const [activeId, setActiveId] = useState(null);
  const [showSection, setShowSection] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);

  const handlePrevClick = () => {
    if (activeId === 'outro') {
      const lastSection = workbook.sections.filter((item) => item).reverse()[0];
      const prevChild = lastSection.items.slice(-1).pop();

      setSelectedSection(prevChild);
      setActiveId(prevChild?._id);
      setShowSection(prevChild?.item_id.sectionid);
      return;
    }

    const currentChild = workbook.sections.flatMap((section) => section.items).find((child) => child._id === activeId);
    const currentIndex = workbook.sections.flatMap((section) => section.items).indexOf(currentChild);
    const prevChild = workbook.sections.flatMap((section) => section.items)[currentIndex - 1];
    if (!prevChild) {
      setSelectedSection('intro');
      setActiveId('intro');
      setShowSection('intro');
      return;
    }

    setActiveId(prevChild?._id);
    setShowSection(prevChild?.item_id.sectionid);
    setSelectedSection(prevChild);
  };

  const handleNextClick = () => {
    const currentChild = workbook.sections.flatMap((section) => section.items).find((child) => child._id === activeId);
    const currentIndex = workbook.sections.flatMap((section) => section.items).indexOf(currentChild);
    const nextChild = workbook.sections.flatMap((section) => section.items)[currentIndex + 1];
    if (!nextChild) {
      setSelectedSection('outro');
      setActiveId('outro');
      setShowSection('outro');
      return;
    }

    if (selectedSection === 'outro') {
      return;
    }

    setActiveId(nextChild?._id);
    setShowSection(nextChild?.item_id.sectionid);
    setSelectedSection(nextChild);
  };

  return (
    <Modal onClose={setPreview}>
      <div className={styles.book}>
        <Title
          onClick={() => {
            setSelectedSection('intro');
            setActiveId('intro');
            setShowSection('intro');
          }}
        >
          {workbook?.title}
        </Title>
        <div className={styles.book_container}>
          <div className={styles.book_selector}>
            {workbook && (
              <LayerList
                workbookTitle={workbook?.title}
                data={workbook?.sections}
                activeId={activeId}
                setActiveId={setActiveId}
                showSection={showSection}
                setShowSection={setShowSection}
                onClick={setSelectedSection}
                preview
              />
            )}
          </div>
          <div className={styles.book_content}>
            <div className={styles.book_section}>
              {selectedSection === 'intro' && <RenderString content={workbook?.intro} />}
              {selectedSection === 'outro' && <RenderString content={workbook?.outro} />}
              {selectedSection && (
                <WorkbookItem
                  title={selectedSection?.item_id?.title}
                  description={selectedSection?.item_id?.description}
                  type={selectedSection?.item_id?.type}
                  content={selectedSection?.item_id?.content}
                  questions={selectedSection?.item_id?.questions}
                />
              )}
            </div>
            <div className={styles.book_footer}>
              <Button variant="primary" onClick={handlePrevClick}>
                <ChevronLeftIcon
                  fontSize="large"
                  sx={{
                    fill: '#fff'
                  }}
                />{' '}
                Prev
              </Button>
              <Button variant="primary" onClick={handleNextClick}>
                Next{' '}
                <ChevronRightIcon
                  fontSize="large"
                  sx={{
                    fill: '#fff'
                  }}
                />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PreviewWorkbooks;
